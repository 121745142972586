<template>
    <Progressbar v-if="loading" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-10 pb-14 ">
        <div class="container">
            <section class="lg:grid lg:grid-cols-24 lg:gap-6 flex flex-col">
                <div class="xl:col-span-6 col-span-8 lg:mb-0 mb-5">
                    <ul class="text-lg hidden lg:block" :class="{ 'pointer-events-none': step == 5 }">
                        <li class="mb-2 last:mb-0">
                            <button
                                :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 1, 'hover:!border-opacity-100 hover:!border-blue-700': step > 1 }"
                                :disabled="step == 1" @click="setStep(1)"
                                class="px-6 w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300 group bg-white border-2 dark:bg-base-300 dark:border-transparent  rounded-2xl flex items-center">
                                <span :class="{ '!bg-blue-700 !bg-opacity-100': step >= 1 }"
                                    class="w-9 bg-gray-350 group-hover:bg-opacity-100 transition duration-200 h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <svg class="" width="17" height="19" viewBox="0 0 17 19" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path class="fill-current text-white transition duration-200"
                                            fill-rule="evenodd" clip-rule="evenodd"
                                            d="M7.03704 0C6.64839 0 6.33333 0.315059 6.33333 0.703704V1.40741H5.62963C4.98408 1.40741 4.44005 1.84203 4.27418 2.43462C0.852165 3.16292 0 5.29734 0 10.5556C0 17.5096 1.49044 19 8.44444 19C15.3984 19 16.8889 17.5096 16.8889 10.5556C16.8889 5.29734 16.0367 3.16292 12.6147 2.43462C12.4488 1.84203 11.9048 1.40741 11.2593 1.40741H10.5556V0.703704C10.5556 0.315059 10.2405 0 9.85185 0C9.46321 0 9.14815 0.315059 9.14815 0.703704V1.40741H7.74074V0.703704C7.74074 0.315059 7.42568 0 7.03704 0ZM4.92593 14.0741C4.53728 14.0741 4.22222 14.3891 4.22222 14.7778C4.22222 15.1664 4.53728 15.4815 4.92593 15.4815H11.963C12.3516 15.4815 12.6667 15.1664 12.6667 14.7778C12.6667 14.3891 12.3516 14.0741 11.963 14.0741H4.92593ZM4.22222 11.2593C4.22222 10.8706 4.53728 10.5556 4.92593 10.5556H11.963C12.3516 10.5556 12.6667 10.8706 12.6667 11.2593C12.6667 11.6479 12.3516 11.963 11.963 11.963H4.92593C4.53728 11.963 4.22222 11.6479 4.22222 11.2593ZM4.92593 8.44444C4.53728 8.44444 4.22222 8.12939 4.22222 7.74074C4.22222 7.3521 4.53728 7.03704 4.92593 7.03704H11.963C12.3516 7.03704 12.6667 7.3521 12.6667 7.74074C12.6667 8.12939 12.3516 8.44444 11.963 8.44444H4.92593Z"
                                            fill="#3B82F6"></path>
                                    </svg> </span>
                                {{ __('Setting the sending text') }}
                            </button>
                        </li>
                        <li class="mb-2 last:mb-0">
                            <button
                                :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 2, 'hover:text-blue-700 hover:border-blue-700': step > 2 }"
                                :disabled="step <= 2" @click="setStep(2)"
                                class="px-6  w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300  group group bg-white dark:bg-base-300 dark:border-transparent border-2 rounded-2xl  flex items-center">
                                <span
                                    :class="{ '!bg-blue-700 !bg-opacity-100': step >= 2, 'group-hover:bg-opacity-100': step > 2 }"
                                    class="w-9 bg-gray-350 transition duration-200  h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <svg class="" width="14" height="20" viewBox="0 0 14 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            class="stroke-current text-white transition duration-200 hover:text-blue-700"
                                            d="M1 3.8C1 2.2536 2.2536 1 3.8 1H10.2C11.7464 1 13 2.2536 13 3.8V16.2C13 17.7464 11.7464 19 10.2 19H3.8C2.2536 19 1 17.7464 1 16.2V3.8Z"
                                            stroke-width="1.5"></path>
                                        <circle
                                            class="fill-current text-white transition duration-200 hover:text-blue-700"
                                            cx="7" cy="16" r="1"></circle>
                                        <path
                                            class="stroke-current text-white transition duration-200 hover:text-blue-700"
                                            d="M5 4L9 4" stroke-width="1.5" stroke-linecap="round"></path>
                                    </svg> </span>
                                {{ __('Shipping Method') }}
                            </button>
                        </li>
                        <li class="mb-2 last:mb-0">
                            <button
                                :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 3, 'hover:text-blue-700 hover:border-blue-700': step > 3 }"
                                :disabled="step <= 3" @click="setStep(3)"
                                class="px-6  w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300  group group bg-white dark:bg-base-300 dark:border-transparent rounded-2xl border-2  flex items-center">
                                <span
                                    :class="{ '!bg-blue-700 !bg-opacity-100': step >= 3, 'group-hover:bg-opacity-100': step > 3 }"
                                    class="w-9 bg-gray-350 transition duration-200  h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="2" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            class="stroke-current text-white"
                                            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                                    </svg>
                                </span>
                                {{ __('Send settings') }}
                            </button>
                        </li>
                        <li class="mb-2 last:mb-0">
                            <button
                                :class="{ '!text-blue-700  !border-opacity-100 ': step === 4, 'hover:text-blue-700 hover:border-blue-700': step > 4 }"
                                disabled
                                class="px-6  w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300  group group bg-white dark:bg-base-300 dark:border-transparent  rounded-2xl border-2  flex items-center">
                                <span
                                    :class="{ '!bg-blue-700 !bg-opacity-100': step >= 4, 'group-hover:bg-opacity-100': step > 4 }"
                                    class="w-9 bg-gray-350 transition duration-200  h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <svg class="" width="19" height="19" viewBox="0 0 19 19" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            class="stroke-current text-white transition duration-200 hover:text-blue-700"
                                            d="M1 9.5C1 11.433 1.10488 12.9181 1.36041 14.0679C1.61342 15.2063 2.00064 15.9539 2.52339 16.4766C3.04614 16.9994 3.79369 17.3866 4.93214 17.6396C6.08194 17.8951 7.56701 18 9.5 18C11.433 18 12.9181 17.8951 14.0679 17.6396C15.2063 17.3866 15.9539 16.9994 16.4766 16.4766C16.9994 15.9539 17.3866 15.2063 17.6396 14.0679C17.8951 12.9181 18 11.433 18 9.5C18 7.56701 17.8951 6.08194 17.6396 4.93214C17.3866 3.79369 16.9994 3.04614 16.4766 2.52339C15.9539 2.00064 15.2063 1.61342 14.0679 1.36041C12.9181 1.10488 11.433 1 9.5 1C7.56701 1 6.08194 1.10488 4.93214 1.36041C3.79369 1.61342 3.04614 2.00064 2.52339 2.52339C2.00064 3.04614 1.61342 3.79369 1.36041 4.93214C1.10488 6.08194 1 7.56701 1 9.5Z"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path
                                            class="stroke-current text-white transition duration-200 hover:text-blue-700"
                                            d="M4.75 11.0833C4.75 11.0833 6.94235 5.54159 9.50002 9.49992C12.0577 13.4583 14.25 7.91659 14.25 7.91659"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg> </span>
                                {{ __('Details and final approval') }}
                            </button>
                        </li>
                        <li class="mb-2 last:mb-0">
                            <button
                                :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 5, ' hover:text-blue-700 hover:border-blue-700': step > 5 }"
                                disabled
                                class="px-6  w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60 border-gray-300  group group bg-white dark:bg-base-300 dark:border-transparent border-2 rounded-2xl   flex items-center">
                                <span :class="{ '!bg-blue-700 !bg-opacity-100': step === 5 }"
                                    class="w-9 bg-gray-350 transition duration-200  h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="2" class="w-6 h-6">
                                        <path class="stroke-current text-white" stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                    </svg>
                                </span>
                                {{ __('Complete the process') }}
                            </button>
                        </li>
                    </ul>
                    <div class="relative group w-full hidden">
                        <div
                            class=" bg-white dark:bg-base-300 text-blue-700 dark:text-white cursor-pointer  py-2 flex items-center justify-between px-3 h-12 rounded">
                            <span class="flex font-medium text-lg  items-center">
                                <svg width="17" height="19" viewBox="0 0 17 19" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.03704 0C6.64839 0 6.33333 0.315059 6.33333 0.703704V1.40741H5.62963C4.98408 1.40741 4.44005 1.84203 4.27418 2.43462C0.852165 3.16292 0 5.29734 0 10.5556C0 17.5096 1.49044 19 8.44444 19C15.3984 19 16.8889 17.5096 16.8889 10.5556C16.8889 5.29734 16.0367 3.16292 12.6147 2.43462C12.4488 1.84203 11.9048 1.40741 11.2593 1.40741H10.5556V0.703704C10.5556 0.315059 10.2405 0 9.85185 0C9.46321 0 9.14815 0.315059 9.14815 0.703704V1.40741H7.74074V0.703704C7.74074 0.315059 7.42568 0 7.03704 0ZM4.92593 14.0741C4.53728 14.0741 4.22222 14.3891 4.22222 14.7778C4.22222 15.1664 4.53728 15.4815 4.92593 15.4815H11.963C12.3516 15.4815 12.6667 15.1664 12.6667 14.7778C12.6667 14.3891 12.3516 14.0741 11.963 14.0741H4.92593ZM4.22222 11.2593C4.22222 10.8706 4.53728 10.5556 4.92593 10.5556H11.963C12.3516 10.5556 12.6667 10.8706 12.6667 11.2593C12.6667 11.6479 12.3516 11.963 11.963 11.963H4.92593C4.53728 11.963 4.22222 11.6479 4.22222 11.2593ZM4.92593 8.44444C4.53728 8.44444 4.22222 8.12939 4.22222 7.74074C4.22222 7.3521 4.53728 7.03704 4.92593 7.03704H11.963C12.3516 7.03704 12.6667 7.3521 12.6667 7.74074C12.6667 8.12939 12.3516 8.44444 11.963 8.44444H4.92593Z"
                                        fill="currentColor"></path>
                                </svg>
                                <span class="mr-2 mt-2"></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="xl:col-span-18 col-span-16">
                    <div class="bg-white dark:bg-base-300 rounded-2xl p-3 md:p-8 lg:p-4 xl:p-8">
                        <Form @submit="onSubmit" v-slot="{ errors }">
                            <div class="flex flex-col step-1" v-if="step == 1">

                                <h2 class="text-dark-550  dark:text-white font-bold flex items-center mb-8 text-2xl">
                                    <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                                    {{ __('Setting the sending text') }}
                                </h2>
                                <div class="grid md:gap-x-14 gap-y-6">
                                    <div class="flex flex-col items-start">
                                        <button type="button" class="btn btn-base btn-outline btn-info"
                                            :class="{ 'loading': loadingBtn }" @click="getMessages()">
                                            {{ __('Choose from saved texts') }}
                                        </button>
                                        <Field as="textarea" v-model="sendMessage.content" rules="required" rows="5"
                                            @keyup="counter" class="textarea w-full mt-2"
                                            :placeholder="__('Enter the text of your sendMessage...')" name="name" />
                                        <div class="flex w-full mt-1">
                                            <button type="button"
                                                class="btn btn-sm no-animation cursor-default btn-active">
                                                {{ __('Number of parts') }}: {{ sms.messages }}
                                            </button>
                                            <button type="button"
                                                class="btn btn-sm mr-auto  no-animation cursor-default btn-active">
                                                {{ __('Number of characters') }}: {{ sms.length }}
                                            </button>
                                            <button type="button"
                                                class="btn btn-sm mr-2 no-animation cursor-default btn-active">
                                                {{ __('Language') }}: {{ sms.encoding === 'UTF16' ? 'FA' : 'EN'
                                                }}
                                            </button>
                                        </div>
                                    </div>

                                </div>

                                <button type="button" @click="setStep(2)" class="btn btn-primary mt-7 mr-auto px-8">
                                    {{ __('Shipping Method') }}
                                </button>
                            </div>
                            <div class="flex flex-col step-2" v-else-if="step == 2">

                                <h2 class="text-dark-550  dark:text-white font-bold flex items-center mb-8 text-2xl">
                                    <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                                    {{ __('Shipping Method') }}
                                </h2>
                                <div class="grid md:gap-x-14 gap-y-6">
                                    <div class="flex flex-col items-start">
                                        <p class="mb-3">{{ __('Post Type') }}</p>
                                        <div class="flex mb-2 flex-wrap">
                                            <label class="label cursor-pointer">
                                                <input :checked="sendMessage.type == 'test'"
                                                    class="radio radio-secondary" @change="setType('test')" value="test"
                                                    type="radio" name="type" />
                                                <span class="label-text mr-2">{{ __('Test delivery')
                                                    }}</span>
                                            </label>
                                            <label class="label cursor-pointer mr-3">
                                                <input :checked="sendMessage.type == 'clue'" value="clue"
                                                    @change="setType('clue')" class="radio radio-secondary" type="radio"
                                                    name="type" />
                                                <span class="label-text mr-2">
                                                    {{ __('Send to clues') }}
                                                </span>
                                            </label>
                                            <label class="label cursor-pointer md:mr-3">
                                                <input :checked="sendMessage.type == 'account'" value="account"
                                                    @change="setType('account')" class="radio radio-secondary"
                                                    type="radio" name="type" />
                                                <span class="label-text mr-2">
                                                    {{ __('Send to accounts') }}
                                                </span>
                                            </label>
                                        </div>
                                        <div class="w-full flex mt-4 lg:mt-0 justify-end"
                                            v-if="sendMessage.type != 'test'">
                                            <input
                                                class="input w-full h-10 xl:pl-36 placeholder-gray-320 md:w-64 xl:w-80 ml-1 bg-gray-350 bg-opacity-10 rounded-lg  transition duration-200 text-sm text-gray-350 px-3 font-medium dark:!bg-base-100 dark:!text-white  focus:bg-blue-700 focus:bg-opacity-10 border border-blue-700  focus:border-opacity-100"
                                                type="text" :placeholder="__('Search...')" v-model="searchValue">
                                            <button type="button" @click="search"
                                                class=" bg-blue-600 disabled:opacity-70 disabled:!bg-blue-600 flex items-center h-10 md:px-5 px-3 rounded-md text-white font-semibold text-sm transition duration-200 hover:bg-blue-800 ">
                                                {{ __('Search') }}
                                            </button>
                                            <button type="button" @click="selectAll()"
                                                class=" border border-solid border-green-600 disabled:opacity-70 mr-3 flex items-center h-10 md:px-5 px-3 rounded-md text-white font-semibold text-xs lg:text-sm transition duration-200 hover:bg-green-800">
                                                {{ __('Select all') }}
                                            </button>
                                        </div>
                                        <div class="grid mt-3 xl:grid-cols-7 w-full gap-3"
                                            v-if="sendMessage.type == 'clue'">
                                            <div class="flex xl:col-span-4 h-56 bg-base-200 justify-center flex-col items-center my-auto"
                                                v-if="!clues.length">
                                                <NotFound :small="true" />
                                            </div>
                                            <div v-if="clues.length"
                                                class="card xl:col-span-4 bg-base-200 h-56 md:h-auto w-full max-h-370 scrollbar-small clue">
                                                <div class="card-body px-2 py-4 md:p-4">
                                                    <div
                                                        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-4 gap-1 md:gap-5 items-baseline">
                                                        <button type="button" @click="addItem('clues', clue)"
                                                            class="cursor-pointer hover:bg-gray-700 dark:hover:bg-gray-800 hover:bg-opacity-5 hover:rounded-lg relative text-center"
                                                            v-for="clue in clues">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke-width="1.5"
                                                                stroke="currentColor" class="w-12 h-12 m-auto">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                                            </svg>

                                                            <p class="text-center mt-1 text-sm">{{
                                                                clue.fullname }}</p>
                                                            <p class="text-center text-sm ">{{
                                                                clue.phone }}</p>
                                                        </button>
                                                    </div>
                                                    <LoadMore :errors="error" @tryAgain="loadMore"
                                                    v-if="meta.current_page < meta.last_page" />
                                                </div>
                                            </div>
                                            <div
                                                class="card xl:col-span-3 bg-base-200 h-56 md:h-auto w-full max-h-370 scrollbar-small">
                                                <div class="card-body px-2 py-4 md:p-4">
                                                <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-3 gap-1 md:gap-5 items-baseline"
                                                    :class="{ 'h-full': !sendMessage.clues.length }">
                                                    <button type="button" @click="removeItem('clues', clue)"
                                                        class="cursor-pointer hover:bg-gray-700 dark:hover:bg-gray-800 hover:bg-opacity-5 hover:rounded-lg relative text-center"
                                                        v-for="clue in sendMessage.clues">
                                                        <MinusCircleIcon class="w-5 h-5 text-error absolute top-0 right-0" />
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                            viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                            class="w-12 h-12 m-auto">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                                        </svg>

                                                        <p class="text-center mt-1 text-sm">{{
                                                            clue.fullname }}</p>
                                                        <p class="text-center text-sm ">{{
                                                            clue.phone }}</p>
                                                    </button>
                                                    <p v-if="!sendMessage.clues.length"
                                                        class="col-span-full flex justify-center items-center h-full text-lg text-gray-300 font-bold">
                                                        {{ __('No item selected.') }}</p>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid mt-3 xl:grid-cols-7 w-full gap-3"
                                            v-else-if="sendMessage.type == 'account'">
                                            <div class="flex flex-col xl:col-span-4 justify-center bg-base-200 h-56 items-center my-auto"
                                                v-if="!accounts.length">
                                                <NotFound :small="true" />
                                            </div>
                                            <div v-if="accounts.length"
                                                class="card xl:col-span-4 mt-3 bg-base-200 h-56 md:h-auto w-full max-h-370 scrollbar-small account">
                                                <div class="card-body px-2 py-4 md:p-4">
                                                    <div
                                                      class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-4 gap-1 md:gap-5 items-baseline">
                                                        <button type="button"
                                                            class="cursor-pointer hover:bg-gray-700 dark:hover:bg-gray-800 hover:bg-opacity-5 hover:rounded-lg relative text-center"
                                                            v-for="account in accounts"
                                                            @click="addItem('accounts', account)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke-width="1.5"
                                                                stroke="currentColor" class="w-12 h-12 m-auto">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                                            </svg>

                                                            <p class="text-center mt-1 text-sm">{{
                                                                account.name }}</p>
                                                            <p class="text-center text-sm ">{{
                                                                account.phone }}</p>
                                                        </button>
                                                    </div>
                                                    <LoadMore :errors="error" @tryAgain="loadMore"
                                                        v-if="meta.current_page < meta.last_page" />
                                                </div>
                                            </div>
                                            <div
                                                class="card xl:col-span-3 mt-3 bg-base-200 h-56 md:h-auto w-full max-h-370 scrollbar-small">
                                                <div class="card-body px-2 py-4 md:p-4">
                                                     <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-3 gap-1 md:gap-5 items-baseline"
                                                        :class="{ 'h-full': !sendMessage.accounts.length }">
                                                        <button type="button" @click="removeItem('accounts', account)"
                                                            class="cursor-pointer hover:bg-gray-700 dark:hover:bg-gray-800 hover:bg-opacity-5 hover:rounded-lg relative text-center"
                                                            v-for="account in sendMessage.accounts">
                                                            <MinusCircleIcon class="w-5 h-5 text-error absolute top-0 right-0" />
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke-width="1.5"
                                                                stroke="currentColor" class="w-12 h-12 m-auto">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                                            </svg>

                                                            <p class="text-center mt-1 text-sm">{{
                                                                account.name }}</p>
                                                            <p class="text-center text-sm ">{{
                                                                account.phone }}</p>
                                                        </button>
                                                        <p v-if="!sendMessage.accounts.length"
                                                            class="col-span-full flex justify-center items-center h-full text-lg text-gray-300 font-bold">
                                                            {{ __('No item selected.') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-between items-center w-full mt-3">
                                            <label class="label cursor-pointer">
                                                <input type="checkbox" class="toggle toggle-secondary"
                                                    :disabled="sendMessage.type == 'test'"
                                                    v-model="sendMessage.manual" />
                                                <span class="label-text mr-2">
                                                    {{ __('Choose the desired number') }}
                                                </span>
                                            </label>
                                            <p class="text-sm">
                                                {{ __('The number of SMS') }}: {{ messageCount() + ' ' + __('Number') }}
                                            </p>
                                        </div>
                                        <div class="flex flex-col items-start w-full"
                                            :class="{ 'mt-4': sendMessage.type == 'test' }"
                                            v-if="sendMessage.type == 'test' || sendMessage.manual">
                                            <Field as="textarea" v-model="sendMessage.manual_number" rows="4"
                                                class="textarea w-full" name="manual_number"
                                                :placeholder="__('Favorite numbers...')" />
                                            <small class="text-red-500 mt-1">{{ __('One mobile number per line')
                                                }}</small>
                                            <div class="flex items-center mt-3 w-full">
                                                <input type="file"
                                                    class="file-input file-input-bordered file-input-primary pr-0 w-full max-w-sm"
                                                    name="excel" @change="importContact($event, false)"
                                                    :disabled="loadingBtn" />
                                                <button @click="clearExcel" class="btn btn-square btn-ghost btn-sm mr-3"
                                                    v-if="sendMessage.excel.length">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                        class="w-6 h-6 text-error">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </button>
                                                <LoadingBar color="text-blue-500" class="mr-3 !w-5" v-if="loadingBtn" />
                                            </div>
                                            <label for="excel-modal" class="btn btn-ghost btn-sm text-blue-400 mt-1">
                                                {{ __('Excel file production guide') }}
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                <div class="flex mt-7 mr-auto">
                                    <button type="button" @click="setStep(1, false)"
                                        class="btn btn-outline btn-error px-8">
                                        {{ __('Back') }}
                                    </button>
                                    <button type="button" @click="setStep(3)" class="btn btn-primary mr-2 px-8"
                                        :disabled="loadingBtn">
                                        {{ __('Send settings') }}
                                    </button>
                                </div>
                            </div>
                            <div class="flex flex-col step-3" v-else-if="step == 3">

                                <h2 class="text-dark-550  dark:text-white font-bold flex items-center mb-8 text-2xl">
                                    <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                                    {{ __('Send settings') }}
                                </h2>

                                <div class="grid md:gap-x-14 gap-y-6">
                                    <div class="flex flex-col items-start">
                                        <p class="mb-3">{{ __('postage date') }}</p>
                                        <div class="flex">
                                            <label class="label cursor-pointer">
                                                <Field v-model="sendMessage.date" rules="required"
                                                    class="radio radio-secondary" value="immediately" type="radio"
                                                    name="date" />
                                                <span class="label-text mr-2">{{ __('Ship immediately')
                                                    }}</span>
                                            </label>
                                            <label class="label cursor-pointer mr-3">
                                                <Field v-model="sendMessage.date" rules="required" value="special"
                                                    class="radio radio-secondary" type="radio" name="date" />
                                                <span class="label-text mr-2">
                                                    {{ __('Send on the specified date') }}
                                                </span>
                                            </label>
                                        </div>
                                        <Field v-model="sendMessage.shipping_time" v-if="sendMessage.date == 'special'"
                                            name="shipping_time" :placeholder="__('Choose the time')" dir="ltr" readonly
                                            class="input text-right max-w-72 shipping_time-input cursor-pointer"
                                            :class="{ 'input-error': errors.shipping_time }" />
                                        <DatePicker v-model="sendMessage.shipping_time"
                                            custom-input=".shipping_time-input" :min="minDate" :max="maxDate"
                                            type="datetime" />
                                        <div class="divider"></div>
                                        <p class="mb-3">{{ __('Select the number') }}</p>
                                        <div class="flex flex-wrap">
                                            <label class="label cursor-pointer ml-3" v-for="number in numbers">
                                                <Field v-model="sendMessage.census" rules="required" :value="number"
                                                    class="radio radio-secondary" type="radio" name="number" />
                                                <span class="label-text mr-2">{{ number }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex mt-7 mr-auto">
                                    <button type="button" @click="setStep(2, false)"
                                        class="btn btn-outline btn-error px-8">
                                        {{ __('Back') }}
                                    </button>
                                    <button type="button" @click="setStep(4)" class="btn btn-primary mr-2 px-8">
                                        {{ __('Details and final approval') }}
                                    </button>
                                </div>
                            </div>
                            <div class="flex flex-col step-4" v-else-if="step == 4">

                                <h2 class="text-dark-550  dark:text-white font-bold flex items-center mb-8 text-2xl">
                                    <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                                    {{ __('Details and final approval') }}
                                </h2>

                                <div class="grid md:gap-x-14 gap-y-6">
                                    <div class="flex flex-col items-start">
                                        <div class="card bg-base-200 w-full">
                                            <div class="card-body p-4">
                                                <p class="mb-1 font-bold">{{ __('SMS text') }}</p>
                                                <p>{{ sendMessage.content }}</p>
                                            </div>
                                        </div>
                                        <div class="card bg-base-200 w-full mt-4 max-h-370 scrollbar-small"
                                            v-if="sendMessage.numbers.length">
                                            <div class="card-body p-4">
                                                <p class="mb-1 font-bold">{{ __('Selected numbers') }}</p>
                                                <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-4"
                                                    dir="ltr">
                                                    <p v-for="number in sendMessage.numbers">{{ number }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card bg-base-200 w-full mt-4">
                                            <div class="card-body p-4">
                                                <p class="mb-1 font-bold">{{ __('detail') }}</p>
                                                <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                                                    <p v-if="sendMessage.manual && sendMessage.shipping_time">{{
                                                        __('shipping time') }}:
                                                        <span dir="ltr">{{ sendMessage.shipping_time }}</span>
                                                    </p>
                                                    <p>{{ __('Census') }}: {{ sendMessage.census }}</p>
                                                    <p>{{ __('The number of SMS') }}: {{ sendMessage.number_count
                                                        + ' ' + __('SMS') }}</p>
                                                    <p>{{ __('The number of SMS parties') }}: {{ sms.messages +
                                                        ' ' + __('Part') }}</p>
                                                    <p>{{ __('Cost per message') }}: {{ sendMessage.cost + ' ' +
                                                        __('Toman') }}</p>
                                                    <p>{{ __('Payable fee') }}: {{ (sendMessage.cost *
                                                        sendMessage.number_count * sms.messages).toLocaleString() + ' '
                                                        +
                                                        __('Toman') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <label class="label cursor-pointer mt-3">
                                            <input type="checkbox" class="checkbox checkbox-secondary"
                                                v-model="sendMessage.block_number" @change="checkMessage" />
                                            <span class="label-text mr-2">
                                                {{ __('Send to blocked numbers') }}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="flex mt-7 mr-auto">
                                    <button type="button" @click="setStep(3, false)"
                                        class="btn btn-outline btn-error px-8">
                                        {{ __('Back') }}
                                    </button>
                                    <button type="submit" class="btn btn-primary mr-2 px-8">
                                        {{ __('Complete the process') }}
                                    </button>
                                </div>
                            </div>
                            <div class="flex flex-col step-5" v-else-if="step == 5">

                                <h2 class="text-dark-550  dark:text-white font-bold flex items-center mb-8 text-2xl">
                                    <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                                    {{ __('Complete the process') }}
                                </h2>

                                <div class="grid md:gap-x-14 gap-y-6">
                                    <div class="flex flex-col items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-16 h-16 text-success">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                        </svg>
                                        <p class="text-lg text-success mt-5">
                                            {{ __('Your request has been successfully submitted') }}
                                        </p>
                                        <p class="mt-3 text-center">
                                            {{ __('You can find out the status of your request by visiting the Posts report section.') }}
                                        </p>
                                        <router-link :to="{ name: 'user.messages' }"
                                            class="btn btn-primary mt-3 px-8">{{
                                                __('Send report') }}</router-link>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="message-modal" class="modal-toggle" />
        <label class="modal" for="message-modal">
            <label class="modal-box overflow-hidden" for="">
                <h3 class="font-bold text-lg mb-4">{{ __('Texts') }}</h3>
                <div class="modal-content" v-if="saveMessages.length">
                    <label for="message-modal" v-for="item in saveMessages"
                        @click="sendMessage.content = item.content, counter()">
                        <div
                            class="card bg-base-200 cursor-pointer rounded-lg border-2 dark:border-gray-500 hover:!border-blue-700 mt-2 ml-2">
                            <div class="card-body p-3 scrollbar-small max-h-20 text-justify gap-0">
                                <p>{{ item.title }}</p>
                                <p class="truncate text-xs block">{{ item.content }}</p>
                            </div>
                        </div>
                    </label>
                </div>
                <p class="mt-4 text-center" v-else>{{ __('Nothing found to display') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="message-modal">
                        {{ __('Close') }}
                    </label>
                </div>
            </label>
        </label>
    </Teleport>
    <Teleport to="#app">
        <input type="checkbox" id="excel-modal" class="modal-toggle" />
        <label class="modal" for="excel-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Excel file production guide') }}</h3>
                <ul class="list-group mt-3">
                    <li class="list-group-item"> ✔ ستون اول حتما شامل شماره تماس باشد.</li>
                    <li class="list-group-item"> ✔ در هر ردیف یک شماره وارد شود.</li>
                    <li class="list-group-item"> ✔ شماره بصورت کامل با فرمت<b> 09xxxxxxxxx </b> وارد نمایید.</li>
                    <li class="list-group-item"> ✔ ستون دوم می‌تواند شامل اسامی باشد.</li>
                    <li class="list-group-item"> ✔ قرار دادن اسامی برای هر شماره دلخواه و غیر اجباری است.</li>
                    <li class="list-group-item text-blue-500"><a :href="excelFile">دانلود فایل نمونه</a>
                    </li>
                </ul>
                <div class="modal-action">
                    <label class="btn btn-sm" for="excel-modal">
                        {{ __('Back') }}
                    </label>
                </div>
            </label>
        </label>
    </Teleport>
</template>
<script setup>
import { onMounted, reactive, computed, watch, ref } from 'vue';
import { Field, Form } from "vee-validate";
import LoadingBar from "../svg/LoadingBar.vue";
import useDashboard from '../../composables/dashboard';
import SmsCounter from 'sms-counter'
import DatePicker from 'vue3-persian-datetime-picker'
import moment from 'moment-jalaali'
import { MinusCircleIcon } from '@heroicons/vue/24/outline';
const { loadingBtn, errors, meta, step, loading, toast, sendMessage,sms, clues, saveMessages, getClues, importContact, checkMessage, getSaveMessages, storeMessage, accounts, getAccounts, numbers } = useDashboard();
const minDate = moment().add(10, 'minute').format('jYYYY/jM/jD HH:mm');
const maxDate = moment().add(3, 'jMonth').format('jYYYY/jM/jD HH:mm');
const error = errors;
const searchValue = ref('');
loading.value = false;
let scrollbar = null;
const query = { per_page: 30, number_type: 'mobile' };
watch(clues,() => {
    if(clues.value.length) Object.assign(sendMessage,{type: 'clue',manual: false});
})
watch(accounts,() => {
    if(accounts.value.length) Object.assign(sendMessage,{type: 'account',manual: false});
});
watch(() => sendMessage.type, (value) => setTimeout(() => scroll(value), 100));
const setType = (type) => {
    if(scrollbar) document.removeEventListener('scroll',scrollbar);
    if (type == 'clue') {
        getClues(query);
        return false
    } else if (type == 'account') {
        getAccounts(query);
        return false;
    } else {
        sendMessage.manual = true;
        sendMessage.type = type;
    }
}
const search = () => {
    query.search = searchValue.value;
    setType(sendMessage.type);
}
const selectAll = () => {
    const data = sendMessage.type == 'clue' ? clues.value.filter(item => sendMessage.clues.findIndex(value => value.id == item.id) == -1) : accounts.value.filter(item => sendMessage.accounts.findIndex(value => value.id == item.id) == -1);
    if (data.length) sendMessage[sendMessage.type + 's'].push(...data);
    else sendMessage[sendMessage.type + 's'] = [];
}
const clearExcel = () => {
    sendMessage.excel.length = 0;
    document.querySelector('input[name=excel]').value = null;
}
const messageCount = () => {
    let count = 0;
    if (sendMessage.type == 'test' || sendMessage.manual) count += sendMessage.manual_number.split("\n").filter(value => /^[09][1-9]\d{9}$/g.test(value)).length;
    count += sendMessage.excel.length;
    if (sendMessage.type == 'clue') count += sendMessage.clues.length;
    else if (sendMessage.type == 'account') count += sendMessage.accounts.length;
    return count;
}
const onSubmit = () => storeMessage();
const setStep = (value, init = true) => {
    let error = false;
    if (value == 2 && !sendMessage.content.trim().length) {
        error = true;
        toast.error(window.i18n['Please enter your text']);
    } else if (value == 3) {
        error = false;
        if (!messageCount()) toast.error(window.i18n['No number has been selected to send']);
        else if (init) checkMessage();
        else step.value = 3;
        return false;
    } else if (value == 4 && sendMessage.date == 'special' && !sendMessage.shipping_time) {
        error = true;
        toast.error(window.i18n['Please select a delivery time']);
    }
    if (!error) step.value = value;
};
const addItem = (type, value) => {
    if (sendMessage[type].findIndex(item => item.id == value.id) == -1) sendMessage[type].push(value);
}

const removeItem = (type, value) => sendMessage[type] = sendMessage[type].filter(item => item.id != value.id);

const getMessages = () => {
    if (saveMessages.value.length) document.getElementById('message-modal').checked = true;
    else getSaveMessages({ per_page: 100 }, 'message-modal');
}
const excelFile = computed(() => window.APP_URL + '/assets/uploads/basic/contact-example.xlsx');
const counter = () => Object.assign(sms, SmsCounter.count(sendMessage.content));
const loadMore = () => {
    const q = { page: meta.current_page + 1, ...query, inline: true };
    if (sendMessage.type == 'clue') getClues(q);
    else if (sendMessage.type == 'account') getAccounts(q);
}
const scroll = (type) => {
    scrollbar = document.querySelector('.card.' + type);
    scrollbar?.addEventListener('scroll', (e) => {
        if (meta.current_page < meta.last_page && !loadingBtn.value && !errors.value && (scrollbar.scrollTop + scrollbar.offsetHeight) >= (scrollbar.scrollHeight - 8)) {
            loadMore();
        }
    });
}
onMounted(() => {
    counter();
});
</script>