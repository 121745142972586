<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!appointment.first_name && errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex justify-between items-center mb-6">
                <h3 class="flex items-center md:text-26 text-2xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __(baseName.capitalize() + ' details') }}
                </h3>
                <div class="flex justify-end order-first lg:order-last">
                    <router-link :to="{ name: 'user.appointment.edit', params: { id: appointment.id } }"
                        class="btn btn-outline btn-info btn-sm"
                        :class="{ 'btn-disabled': !can('appointment.edit.all') && !can('appointment.edit.self', appointment.self) }">
                        {{ __('Edit') }}
                    </router-link>
                    <label @click="setItem(appointment)" for="delete-modal"
                        :class="{ 'btn-disabled': !can('appointment.delete.all') && !can('appointment.delete.self', appointment.self) }"
                        class="btn btn-outline btn-error btn-sm mr-2">
                        {{ __('Delete') }}
                    </label>
                </div>
            </div>
            <div class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse-plus collapse">
                <input type="checkbox" checked />
                <div class="collapse-title text-xl font-medium">
                    {{ __(baseName.capitalize() + ' information') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Subject') }}: <span class="text-black dark:text-white">{{
                            appointment.subject
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Parent Type') }}: <span class="text-black dark:text-white">{{
                             __(appointment.parent_type?.capitalize())
                                }}</span></p>
                        <p class="text-gray-70">{{ __(appointment.parent_type?.capitalize()) }}: <span
                                class="text-black dark:text-white">{{
                                    appointment.model?.name
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Status') }}: <span class="text-black dark:text-white">{{
                            __(appointment.status?.replace('_', ' ')?.capitalize()) || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __(baseName == 'task' ? 'Start time' : 'Time') }}: <span class="text-black dark:text-white" dir="ltr">{{
                            appointment.date || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Duration') }}: <span class="text-black dark:text-white">{{
                            appointment.period || '---' }}</span></p>
                                                    <p class="text-gray-70" v-if="baseName == 'task'">{{ __('Deadline') }}: <span class="text-black dark:text-white" dir="ltr">{{
                            appointment.deadline || '---' }}</span></p>
                        <p class="text-gray-70" v-if="baseName == 'appointment'">{{ __('Meeting place') }}: <span class="text-black dark:text-white">{{
                            appointment.location || '---' }}</span></p>
                             <p class="text-gray-70" v-if="baseName == 'task'">{{ __('Priority') }}: <span class="text-black dark:text-white">{{
                            __(appointment.priority?.capitalize()) || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('SMS reminder to the user') }}: <span
                                class="text-black dark:text-white">{{
                                    __('Before ' + appointment.sms_reminder_time + ' minute'.capitalize()) || '---'
                                }}</span></p>
                        <p class="text-gray-70" v-if="baseName != 'task'">{{ __('SMS reminder to invitees') }}: <span
                                class="text-black dark:text-white">{{
                                    __('Before ' + appointment.sms_reminder_time_invitees + ' minute'.capitalize()) || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Reminder in the software') }}: <span
                                class="text-black dark:text-white">{{
                                    __('Before ' + appointment.reminder_time + ' minute'.capitalize()) || '---' }}</span>
                        </p>
                        <p class="text-gray-70" v-if="baseName == 'contact'">{{ __('Telephone system internal number') }}: <span
                                class="text-black dark:text-white">{{
                                    appointment.voip_id || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('User') }}: <span class="text-black dark:text-white">{{
                            appointment.user }}</span></p>
                        <p class="text-gray-70">{{ __('Created At') }}: <span class="text-black dark:text-white">{{
                            appointment.created_at }}</span></p>
                        <p class="text-gray-70">{{ __('Update date') }}: <span class="text-black dark:text-white">{{
                            appointment.updated_at }}</span></p>
                        <div class="divider h-auto col-span-full my-0"></div>
                        <p class="text-gray-70">{{ __('Description') }}: <span class="text-black dark:text-white">{{
                            appointment.description || '---' }}</span></p>
                    </div>
                </div>
            </div>
            <div v-if="baseName != 'task'"
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('The invitees') }}
                </div>
                <div class="collapse-content">
                    <div class="rounded-2xl dark:bg-base-300 bg-white">
                        <div class=" overflow-x-auto">
                            <table class="table table-xs md:table-sm xl:table-md">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th> {{ __('Type') }} </th>
                                        <th> {{ __('Full Name') }} </th>
                                        <th> {{ __('Phone') }} </th>
                                        <th> {{ __('Email') }} </th>
                                        <th> {{ __('Created At') }} </th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in appointment.invitees">
                                        <td> {{ index + 1 }} </td>
                                        <td> {{ __(item.type.capitalize()) }} </td>
                                        <td> {{ item.fullname }} </td>
                                        <td> {{ item.phone || '---' }} </td>
                                        <td> {{ item.email || '---' }} </td>
                                        <td> {{ item.created_at }} </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="col-span-12 flex flex-col items-center mt-9"
                                v-if="!appointment.invitees.length">
                                <NotFound />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Appendices') }}
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-xs md:table-sm xl:table-md">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{ __('File title') }}</th>
                                    <th>{{ __('File type') }}</th>
                                    <th>{{ __('Status') }}</th>
                                    <th>{{ __('Action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in appointment.attachments">
                                    <th>{{ index + 1 }}</th>
                                    <th>{{ item.name }}</th>
                                    <td>{{ item.mime_type }}</td>
                                    <td>
                                        {{ __(item.url ? 'Uploaded' : 'Uploading') }}
                                    </td>
                                    <td>
                                        <a :href="item.url" target="_blank" class="btn btn-outline btn-info btn-sm">
                                            {{ __('Show') }}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="text-center" v-if="!appointment.attachments.length">
                                <tr>
                                    <td colspan="5">{{ __('No file found to display') }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete ' + baseName) }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this '+baseName+'?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal"
                        :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                            __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removeAppointment"
                        :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>
</template>
<script setup>
import useDashboard from "../../composables/dashboard";
import { computed, onMounted, reactive, ref } from 'vue';
const { getAppointment, appointment, route, loading, meta, errors, remove, removeAppointment, loadingBtn } = useDashboard();
const baseName = route.path.split("/").reverse()[1];
const setItem = (item) => Object.assign(remove, { id: item.id,type: item.type, single: true });
const getData = () => {
    getAppointment();
}
onMounted(() => {
    getData();
});
</script>