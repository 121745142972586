import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useInvoice(navigation) {
  const { route, router } = navigation;
  const invoices = ref([]);
  const invoice = reactive({
    status: "not_sent",
    tax: 0,
    shipping_cost: 0,
    deposit_amount: 0,
    miscellaneous_additions: 0,
    billing: {},
    payments: [],
    items: [],
    attachments: [],
  });
  const getInvoices = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/invoice" + saerch);
      loading.value = false;
      invoices.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e.message;
      loading.value = false;
    }
  };

  const getInvoice = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/invoice/" + route.params.id);
      loading.value = false;
      Object.assign(invoice, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addInvoice = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/invoice/create");
      loading.value = false;
      Object.assign(meta, { ...response.data });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editInvoice = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get(
        "user/invoice/" + route.params.id + "/edit"
      );
      loading.value = false;
      Object.assign(invoice, response.data.data);
      Object.assign(meta, { ...response.data });
      Object.assign(meta,{account: [invoice.account]});
      if(invoice.parent_type)Object.assign(meta,{[invoice.parent_type] : [invoice.model]});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeInvoice = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/invoice", invoice);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({ name: "user.invoices" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateInvoice = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put(
        "user/invoice/" + route.params.id,
        invoice
      );
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user.invoices" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeInvoice = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/invoice/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if (remove.single) {
        if (history.state.back) router.back();
        else router.push({ name: "user.invoices" });
      } else getInvoices(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };
  return {
    invoices,
    invoice,
    getInvoices,
    getInvoice,
    addInvoice,
    editInvoice,
    storeInvoice,
    updateInvoice,
    removeInvoice,
  };
}
