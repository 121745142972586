import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useOpportunity(navigation) {
  const { route, router } = navigation;
  const opportunities = ref([]);
  const opportunity = reactive({
    attachments: [],
  });
  const getOpportunities = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/opportunity" + saerch);
      loading.value = false;
      opportunities.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getOpportunity = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/opportunity/" + route.params.id);
      loading.value = false;
      Object.assign(opportunity, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addOpportunity = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/opportunity/create");
      loading.value = false;
      Object.assign(meta, { ...response.data });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editOpportunity = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get(
        "user/opportunity/" + route.params.id + "/edit"
      );
      loading.value = false;
      Object.assign(opportunity, response.data.data);
      Object.assign(meta, { ...response.data });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeOpportunity = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/opportunity", opportunity);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({ name: "user.opportunities" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateOpportunity = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put(
        "user/opportunity/" + route.params.id,
        opportunity
      );
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user.opportunities" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeOpportunity = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/opportunity/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if (remove.single) {
        if (history.state.back) router.back();
        else router.push({ name: "user.opportunities" });
      } else getOpportunities(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  return {
    opportunities,
    opportunity,
    getOpportunities,
    getOpportunity,
    addOpportunity,
    editOpportunity,
    storeOpportunity,
    updateOpportunity,
    removeOpportunity,
  };
}
