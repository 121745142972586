
import axios from "axios";
import { reactive,ref } from "vue";
import { loading, errors, toast ,meta, loadingBtn, remove } from "./shared";

export default function useLeadSource(navigation) {
  const { route, router } = navigation;
  const leadSources = ref([]);
const leadSource = reactive({});
  const getLeadSources = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/lead-source" + search);
      loading.value = false;
      leadSources.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getLeadSource = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/lead-source/" + route.params.id);
      loading.value = false;
      Object.assign(leadSource,response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeLeadSource = async () => {
    try {
      errors.value = "";
      loadingBtn.value = true;
      let response = await axios.post("user/lead-source",leadSource);
      loadingBtn.value = false;
      toast.success(response.data.message);
      document.getElementById("add-edit-modal").checked = false;
      getLeadSources();
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const updateLeadSource = async () => {
    try {
      errors.value = "";
      loadingBtn.value = true;
      let response = await axios.put("user/lead-source/" + leadSource.id, leadSource);
      const index = leadSources.value.findIndex((item) => item.id === leadSource.id);
      leadSources.value[index] = response.data.data;
      loadingBtn.value = false;
      toast.success(response.data.message);
      document.getElementById("add-edit-modal").checked = false;
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const removeLeadSource = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/lead-source/" + leadSource.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      getLeadSources(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };
  return {
    leadSources,
    leadSource,
    getLeadSources,
    getLeadSource,
    storeLeadSource,
    updateLeadSource,
    removeLeadSource,
  };
}
