import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function usePayment(navigation) {
  const { route, router } = navigation;
  const payments = ref([]);
  const payment = reactive({
    pay_type: "",
    status: "",
    attachments: [],
  });
  const getPayments = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/payment" + saerch);
      loading.value = false;
      payments.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e.message;
      loading.value = false;
    }
  };

  const getPayment = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/payment/" + route.params.id);
      loading.value = false;
      Object.assign(payment, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editPayment = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get(
        "user/payment/" + route.params.id + "/edit"
      );
      loading.value = false;
      Object.assign(payment, response.data.data);
      if(payment.parent_type)Object.assign(meta,{[payment.parent_type] : [payment.model]});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storePayment = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/payment", payment);
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user.invoices" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updatePayment = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put(
        "user/payment/" + route.params.id,
        payment
      );
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user.invoices" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removePayment = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/payment/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if (remove.single) {
        if (history.state.back) router.back();
        else router.push({ name: "user.invoices" });
      } else getPayments(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };
  return {
    payments,
    payment,
    getPayments,
    getPayment,
    editPayment,
    storePayment,
    updatePayment,
    removePayment,
  };
}
