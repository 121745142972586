<template>
    <Form @submit="onSubmit" v-slot="{ errors }" @invalid-submit="onInvalidSubmit">
            <div class="flex flex-col">
                <div>
                    <div class="mb-10  last:mb-0">
                        <h2 class="text-dark-550  dark:text-white font-bold flex items-center text-xl">
                            <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                            {{ state }}
                        </h2>
                        <div class="divider col-span-full mt-0 mb-3"></div>
                        <div>
                            <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" id="step-1" v-show="step == 1">
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Nickname') }}
                                    </label>
                                    <Field as="select" v-model="convert.person.salutation"
                                        :class="{ 'input-error': errors.salutation }" class="select" name="salutation">
                                        <option value="">{{ __('Select...') }}</option>
                                        <option v-for="item in salutationTypes" :value="item">{{
                                            __(item) }}</option>
                                    </Field>
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('First name') }} <span class="text-error">*</span>
                                    </label>
                                    <Field v-model="convert.person.first_name" rules="required"
                                        :class="{ 'input-error': errors.first_name }" class="input" type="text"
                                        name="first_name" />
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Last name') }} <span class="text-error">*</span>
                                    </label>
                                    <Field v-model="convert.person.last_name" rules="required"
                                        :class="{ 'input-error': errors.last_name }" class="input" type="text"
                                        name="last_name" />
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Side') }}
                                    </label>
                                    <Field v-model="convert.person.title" :class="{ 'input-error': errors.title }"
                                        class="input" type="text" name="title" />
                                </div>
                                <div class="flex flex-col">
                                    <div class="flex justify-between">
                                        <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                            {{ __('Phone number') }}
                                        </label>
                                        <button @click="addNumber(convert.person.numbers, index)" type="button"
                                            class="btn btn-xs btn-link no-underline">{{ __('Adding')
                                            }}</button>
                                    </div>
                                    <div class="flex mb-2" v-for="(info, index) in convert.person.numbers">
                                        <div class="join w-full">
                                            <select class="select join-item w-auto" v-model="info.type">
                                                <option v-for="item in numberTypes" :value="item">
                                                    {{ __(item.capitalize()) }}
                                                </option>
                                            </select>
                                            <Field class="input join-item" v-model="info.value" name="numbers[value]"
                                                @input="checkNumber('person', $event)" type="number" />
                                        </div>
                                        <div class="mr-1 flex flex-col justify-center">
                                            <button type="button" v-if="convert.person.numbers.length > 1"
                                                class="btn btn-xs btn-error btn-circle" @click="removeNumber(index)">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none"
                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div class="flex flex-col">
                                    <div class="flex justify-between">
                                        <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                            {{ __('Email') }}
                                        </label>
                                        <button @click="addEmail(index)" class="btn btn-xs btn-link no-underline" type="button">{{
                                            __('Adding')
                                            }}</button>
                                    </div>
                                    <div class="flex mb-2" v-for="(info, index) in convert.person.emails">
                                        <input class="input join-item" v-model="info.value" />

                                        <div class="mr-1 flex flex-col justify-center">
                                            <button type="button" v-if="convert.person.emails.length > 1"
                                                class="btn btn-xs btn-error btn-circle" @click="removeEmail(index)">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none"
                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" id="step-2" v-show="step == 2">
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('state of') }} <span class="text-error">*</span>
                                    </label>
                                    <Field as="select" v-model="convert.account.kind" rules="required"
                                        :class="{ 'input-error': errors.type }" class="select" name="kind">
                                        <option value="new">{{ __('Create account') }}</option>
                                        <option value="exists">{{ __('Account selection') }}</option>
                                    </Field>
                                </div>
                                <div class="flex flex-col" v-if="convert.account.kind == 'new'">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Type') }} <span class="text-error">*</span>
                                    </label>
                                    <Field as="select" v-model="convert.account.type" rules="required"
                                        :class="{ 'input-error': errors.account_type }" class="select" name="account_type">
                                        <option value="genuine">{{ __('Genuine') }}</option>
                                        <option value="legal">{{ __('Legal') }}</option>
                                    </Field>
                                </div>
                                <div class="flex flex-col" v-if="convert.account.kind == 'exists'">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Account') }}
                                    </label>
                                    <Field as="select" v-model="convert.account.id"
                                        :class="{ 'input-error': errors.account }" class="select" name="account">
                                        <option value="">{{ __('Select...') }}</option>
                                        <option v-for="item in meta.accounts" :value="item.id">{{ item.name }}</option>
                                    </Field>
                                </div>
                                <div class="flex flex-col" v-if="convert.account.kind == 'new'">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Name') }} <span class="text-error">*</span>
                                    </label>
                                    <Field v-model="convert.account.name" rules="required"
                                        :class="{ 'input-error': errors.name }" class="input" type="text" name="name" />
                                </div>
                                <div class="flex flex-col" v-if="convert.account.kind == 'new'">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Account ID') }}
                                    </label>
                                    <Field v-model="convert.account.account_id"
                                        :class="{ 'input-error': errors.account_id }" class="input" type="text"
                                        name="account_id" />
                                </div>
                                <div class="flex flex-col" v-if="convert.account.kind == 'new'">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Customer code') }}
                                    </label>
                                    <Field v-model="convert.account.account_code"
                                        :class="{ 'input-error': errors.account_code }" class="input" type="text"
                                        name="account_code" />
                                </div>
                                <div class="flex flex-col" v-if="convert.account.kind == 'new'">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Website') }}
                                    </label>
                                    <Field v-model="convert.account.website" :class="{ 'input-error': errors.website }"
                                        class="input" type="text" name="website" />
                                </div>
                                <div class="flex flex-col col-span-full" v-if="convert.account.kind == 'new'">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Description') }}
                                    </label>
                                    <Field as="textarea" v-model="convert.account.description"
                                        :class="{ 'input-error': errors.description }" class="textarea"
                                        name="description" />
                                </div>
                            </div>
                            <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" id="step-3" v-show="step == 3">
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Opportunity name') }} <span class="text-error">*</span>
                                    </label>
                                    <Field v-model="convert.opportunity.name" :rules="isRequired(3)"
                                        :class="{ 'input-error': errors.name }" class="input" type="text" name="name" />
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Currency') }} <span class="text-error">*</span>
                                    </label>
                                    <Field as="select" v-model="convert.opportunity.currency" :rules="isRequired(3)"
                                        :class="{ 'select-error': errors.currency }" class="select" name="currency">
                                        <option value="">{{ __('Select...') }}</option>
                                        <option v-for="item in currencyTypes" :value="item">{{ __(item.capitalize()) }}
                                        </option>
                                    </Field>
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Amount') }} <span class="text-error">*</span>
                                    </label>
                                    <Field v-model="convert.opportunity.amount"
                                        :class="{ 'input-error': errors.amount }" class="input" type="number"
                                        name="amount" :rules="isRequired(3)" />
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Sales stage') }} <span class="text-error">*</span>
                                    </label>
                                    <Field as="select" v-model="convert.opportunity.sales_stage" :rules="isRequired(3)"
                                        :class="{ 'input-error': errors.sales_stage }" class="select"
                                        name="sales_stage">
                                        <option value="">{{ __('Select...') }}</option>
                                        <option v-for="item in salesStageTypes" :value="item">{{
                                            __(item.replaceAll('_',' ').capitalize()) }}</option>
                                    </Field>
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Sale date') }} <span class="text-error">*</span>
                                    </label>
                                    <Field v-model="convert.opportunity.date_closed" name="date_closed" readonly class="input date_closed-input cursor-pointer" :rules="isRequired(3)" :class="{'input-error': errors.date_closed}" />
                                <DatePicker v-model="convert.opportunity.date_closed" custom-input=".date_closed-input" type="date" />
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Probability') }}
                                    </label>
                                    <Field v-model="convert.opportunity.probability"
                                        :class="{ 'input-error': errors.probability }" class="input" type="text"
                                        name="probability" />
                                </div>
                                <div class="flex flex-col col-span-full" v-if="convert.account.kind == 'new'">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Description') }}
                                    </label>
                                    <Field as="textarea" v-model="convert.account.description"
                                        :class="{ 'input-error': errors.description }" class="textarea"
                                        name="description" />
                                </div>
                            </div>
                            <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" id="step-4" v-show="step == 4">
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Type') }} <span class="text-error">*</span>
                                    </label>
                                    <Field as="select" v-model="convert.appointment.type"
                                        :class="{ 'input-error': errors.appointment_type }" class="select" name="appointment_type">
                                        <option value="contact">{{ __('Call scheduling') }}</option>
                                        <option value="appointment">{{ __('Meeting planning') }}</option>
                                    </Field>
                                </div>
                                <div class="flex flex-col">

                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Subject') }} <span class="text-error">*</span>
                                    </label>
                                    <Field v-model="convert.appointment.subject" :rules="isRequired(4)"
                                        :class="{ 'input-error': errors.subject }" class="input" type="text"
                                        name="subject" />
                                </div>
                                <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Time') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="convert.appointment.date" name="date" :rules="isRequired(4)" readonly class="input date-input cursor-pointer text-end" dir="ltr" :class="{'input-error': errors.date}" />
                                <DatePicker v-model="convert.appointment.date" custom-input=".date-input" type="datetime" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Duration') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="convert.appointment.period" name="period" readonly class="input period-input cursor-pointer" :rules="isRequired(4)" :class="{'input-error': errors.period}" />
                                <DatePicker v-model="convert.appointment.period" custom-input=".period-input" type="time" simple  :jump-minute="15" :round-minute="true" :showNowBtn="false" />
                            </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Reminder in the software') }}
                                    </label>
                                    <Field as="select" v-model="convert.appointment.reminder_time"
                                        :class="{ 'input-error': errors.reminder_time }" class="select"
                                        name="reminder_time">
                                        <option value="">{{ __('Select...') }}</option>
                                        <option v-for="item in reminderTimes" :value="item">{{
                                            __('Before ' + item + ' minute'.capitalize()) }}</option>
                                    </Field>
                                </div>
                                <div class="flex flex-col" v-if="convert.appointment.type == 'appointment'">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Meeting place') }}
                                    </label>
                                    <Field v-model="convert.appointment.location"
                                        :class="{ 'input-error': errors.location }" class="input" type="text"
                                        name="location" />
                                </div>
                                <div class="flex flex-col" v-else>
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Telephone system internal number') }}
                                    </label>
                                    <Field v-model="convert.appointment.voip_id"
                                        :class="{ 'input-error': errors.voip_id }" class="input" type="number"
                                        name="voip_id" />
                                </div>
                                <div class="flex flex-col col-span-full" >
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Description') }}
                                    </label>
                                    <Field as="textarea" v-model="convert.account.description"
                                        :class="{ 'input-error': errors.description }" class="textarea"
                                        name="description" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="divider h-auto w-full"></div>
                <div class="text-end">
                    <button type="submit" class="btn btn-primary px-6 mr-2">{{
                        __('Save Changes')
                        }}</button>
                </div>
            </div>
      
    </Form>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import useDashboard from '../../composables/dashboard';
import { Field, Form } from "vee-validate";
import DatePicker from 'vue3-persian-datetime-picker'
const emit = defineEmits('setStep');
const props = defineProps(['clue', 'meta', 'step', 'state','convert']);
const clue = props.clue;
const reminderTimes = ['5', '15', '60', '360', '720', '1440'];
const numberTypes = ['office', 'mobile', 'fax', 'factory', 'home', 'whatsapp', 'other'];
const salutationTypes = ['Mr', 'Ms', 'Eng', 'Dr', 'Prof', 'Mr.Dr', 'Ms.Dr', 'Mr.Eng', 'Ms.Eng'];
const salesStageTypes = ['prospecting', 'qualification', 'proposal_price_quote', 'negotiation_review', 'closed_won', 'closed_lost'];
const currencyTypes = ['rial', 'dollar', 'euro'];
const convert = props.convert;
const isRequired = (step) => convert.types.includes(step.toString().replace(2,'account').replace(3,'opportunity').replace(4,'appointment')) ? 'required' : '';
Object.assign(convert,{
    person: { salutation: clue.salutation, first_name: clue.first_name, last_name: clue.last_name, title: clue.title, numbers: clue.numbers, emails: clue.emails },
    account: {
        type: clue.type,
        kind: 'new',
        name: clue.account_name
    },
    opportunity: {
        name: clue.account_name ? clue.account_name  + ' - ' + window.i18n['Opportunity'] : ''
    },
    appointment: {
        subject: clue.account_name ? clue.account_name + ' - ' + window.i18n['Appointment'] : '',
        type: 'contact'
    }
});
const { convertClue, route, loading, meta, checkNumber } = useDashboard();
const addNumber = (index) => convert.person.numbers.splice(index + 1, 0, { type: 'office', value: '' });
const addEmail = (index) => convert.person.emails.splice(index + 1, 0, { value: '' });
const removeNumber = (i) => convert.person.numbers = convert.person.numbers.filter((item, index) => index != i);
const removeEmail = (i) => convert.person.emails = convert.person.emails.filter((item, index) => index != i);
const onSubmit = () => {
    convertClue(convert);
}
const onInvalidSubmit = ({ errors }) => {
    const id = document.querySelector('.input-error')?.parentElement?.parentElement?.id;
    if(id){
        const step = parseInt(id.split('-')[1]);
        const button = document.querySelectorAll('#steps li')[(props.step >= step ? step : step - 1) - 1]?.querySelector('button');
        if(button)emit('setStep',button,step);
    }
};
</script>