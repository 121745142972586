import axios from "axios";
import { toast,controller } from "./shared";

export default function useUpload(navigation) {
  const uploadFile = async (event, type, item) => {
    try {
      if (!event.target.files.length) return true;
      controller.value = new AbortController();
      const form = new FormData();
      form.append("file", event.target.files[0]);
      form.append("type", type);
      const response = await axios.post("user/file", form, {
        signal: controller.value.signal,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          item.status = percentCompleted;
        },
      });
      Object.assign(item, response.data.media);
      toast.success(response.data.message);
    } catch (error) {
      window.networkError(error);
    }
  };

  const uploadImage = async (event, type, item) => {
    try {
      if (!event.target.files.length) return true;
      window.progressDialog.value = true;
      const file = new FormData();
      file.append("image", event.target.files[0]);
      file.append("type", type);
      const response = await axios.post("user/image", file, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      item[type] = response.data.image;
      window.progressDialog.value = false;
      toast.success(response.data.message);
    } catch (error) {
      window.networkError(error);
      window.progressDialog.value = false;
    }
  };

  const removeImage = async (type = "user") => {
    try {
      window.progressDialog.value = true;
      const file = new FormData();
      file.append("card_id", card.id);
      file.append("type", type);
      const response = await axios.post("user/upload/image/delete", file);
      window.progressDialog.value = false;
      toast.success(response.data.message);
    } catch (error) {
      window.networkError(error);
      window.progressDialog.value = false;
    }
  };

  const removeFile = async (files, index) => {
    const file = files[index];
    if (!file.id) controller.value?.abort();
    files.splice(index, 1);
  };

  return {
    uploadFile,
    uploadImage,
    removeImage,
    removeFile,
  };
}
