import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useClue(navigation) {
  const { route, router } = navigation;
  const clues = ref([]);
  const clue = reactive({
    type: "genuine",
    numbers: [{ type: "office", value: "" }],
    billing: {},
    emails: [{ value: "" }],
    social: {},
    attachments: [],
    status: "new",
  });
  const getClues = async (query = {}) => {
    try {
      errors.value = "";
      if (query.inline) loadingBtn.value = true;
      else loading.value = true;
      const saerch = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/clue" + saerch);
      if (query.inline) loadingBtn.value = false;
      else loading.value = false;
      if (query.per_page && query.inline)
        clues.value.push(...response.data.data);
      else clues.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e.message;
      if (query.inline) loadingBtn.value = false;
      else loading.value = false;
    }
  };

  const getClue = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/clue/" + route.params.id);
      loading.value = false;
      Object.assign(clue, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addClue = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/clue/create");
      loading.value = false;
      Object.assign(meta, { ...response.data });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editClue = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/clue/" + route.params.id + "/edit");
      loading.value = false;
      Object.assign(clue, response.data.data);
      Object.assign(meta, { ...response.data });
      if (
        clue.industry &&
        meta.industries.indexOf((item) => item.title == clue.industry) == -1
      )
        meta.industries.push({ title: clue.industry });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const sendSms = async () => {
    try {
      errors.value = "";
      loadingBtn.value = true;
      const {clue_id,message_id} = clue;
      let response = await axios.post("user/clue/sms", {clue_id,message_id});
      loadingBtn.value = false;
      document.getElementById('message-modal').checked = false;
      toast.success(response.data.message);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const storeClue = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/clue", clue);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({ name: "user.clues" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateClue = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/clue/" + route.params.id, clue);
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user.clues" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const converterClue = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get(
        "user/clue/" + route.params.id + "/converter"
      );
      loading.value = false;
      Object.assign(clue, response.data.data);
      Object.assign(meta, { ...response.data });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const convertClue = async (data) => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post(
        "user/clue/" + route.params.id + "/convert",
        data
      );
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else
        router.push({
          name: "user.clue.show",
          params: { id: route.params.id },
        });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeClue = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/clue/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if (remove.single) {
        if (history.state.back) router.back();
        else router.push({ name: "user.clues" });
      } else getClues(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const importClue = async (e) => {
    try {
      if (!e.excel) return false;
      errors.value = "";
      loadingBtn.value = true;
      const file = new FormData();
      file.append("excel", e.excel);
      let response = await axios.post("user/clue/import", file, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      loadingBtn.value = false;
      toast.success(response.data.message);
      document.getElementById("excel-modal").checked = false;
      getClues(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  return {
    clues,
    clue,
    getClues,
    getClue,
    addClue,
    editClue,
    storeClue,
    sendSms,
    updateClue,
    removeClue,
    converterClue,
    convertClue,
    importClue,
  };
}
