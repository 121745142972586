import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useTicket(navigation) {
  const { route, router } = navigation;
  const tickets = ref([]);
  const ticket = reactive({
    attachments: [],
  });
  const getTickets = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/ticket" + saerch);
      loading.value = false;
      tickets.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e.message;
      loading.value = false;
    }
  };

  const getTicket = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/ticket/" + route.params.id);
      loading.value = false;
      Object.assign(ticket, response.data.data);
      scrollToBottom('chat-container');
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addTicket = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/ticket/create");
      loading.value = false;
      Object.assign(meta, { ...response.data });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeTicket = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/ticket", ticket);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({ name: "user.tickets" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const storeTicketMessage = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post(
        "user/ticket/" + ticket.id + "/message",
        ticket
      );
      progressDialog.value = false;
      toast.success(response.data.message);
      Object.assign(ticket, {
        ...response.data.data,
        message: "",
        attachments: [],
      });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };
  return {
    tickets,
    ticket,
    getTickets,
    getTicket,
    addTicket,
    storeTicket,
    storeTicketMessage,
  };
}
