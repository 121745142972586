<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!invoice.title && errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex justify-between items-center mb-6">
                <h3 class="flex items-center md:text-26 text-2xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Invoice details') }}
                </h3>
                <div class="flex justify-end order-first lg:order-last">
                    <details class="dropdown">
                        <summary class="btn btn-outline btn-info btn-sm">{{ __('Action') }}</summary>
                        <ul class="menu dropdown-content bg-base-100 rounded-box z-[2] w-36 p-2 shadow">
                            <li>
                                <router-link :to="{ name: 'user.invoice.edit', params: { id: invoice.id } }"
                                    :disabled="!can('invoice.edit.all') && !can('invoice.edit.self', invoice.self)">
                                    {{ __('Edit') }}
                                </router-link>
                            </li>
                            <li> 
                                <router-link :to="{ name: 'user.payment.create',query: {i: invoice.id} }"
                                    :disabled="!can('invoice.edit.all') && !can('invoice.edit.self', invoice.self)">
                                    {{ __('Add payment') }}
                                </router-link>
                            </li>
                        </ul>
                    </details>
                    <label @click="setItem(account)" for="delete-modal"
                        :class="{ 'btn-disabled': !can('invoice.delete.all') && !can('invoice.delete.self', invoice.self) }"
                        class="btn btn-outline btn-error btn-sm mr-2">
                        {{ __('Delete') }}
                    </label>
                </div>
            </div>
            <div class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse-plus collapse">
                <input type="checkbox" checked />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Invoice information') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Title') }}: <span class="text-black dark:text-white">{{
                            invoice.title
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Account') }}: <span class="text-black dark:text-white">{{
                            invoice.account?.name || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Invoice date') }}: <span
                                class="text-black dark:text-white">{{
                                    invoice.invoice_date }}</span></p>
                        <p class="text-gray-70">{{ __('Status') }}: <span class="text-black dark:text-white"
                                :class="{'!text-success': invoice.status == 'paid','!text-error': invoice.status == 'canceled'}">{{
                                    __(invoice.status?.replace('_', ' ')?.capitalize()) || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Parent Type') }}: <span class="text-black dark:text-white">{{
                            __(invoice.parent_type?.capitalize()) || '---'
                                }}</span></p>
                        <p class="text-gray-70" v-if="invoice.parent_type">{{ __(invoice.parent_type?.capitalize()) }}:
                            <span class="text-black dark:text-white">{{
                                invoice.model?.name || '---'
                            }}</span>
                        </p>
                        <p class="text-gray-70">{{ __('User') }}: <span class="text-black dark:text-white">{{
                            invoice.user }}</span></p>
                        <p class="text-gray-70">{{ __('Created At') }}: <span class="text-black dark:text-white">{{
                            invoice.created_at }}</span></p>
                        <p class="text-gray-70">{{ __('Update date') }}: <span class="text-black dark:text-white">{{
                            invoice.updated_at }}</span></p>
                        <div class="divider h-auto col-span-full my-0"></div>
                        <p class="text-gray-70">{{ __('Description') }}: <span class="text-black dark:text-white">{{
                            invoice.description || '---' }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('More information') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Reminder SMS to customer') }}: <span
                                class="text-black dark:text-white">{{
                                    __('Before ' + invoice.sms_reminder_time + ' minute'.capitalize()) || '---' }}</span>
                        </p>
                        <p class="text-gray-70">{{ __('How to send goods') }}: <span
                                class="text-black dark:text-white">{{
                                    __(invoice.shipping_type?.capitalize()) || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Payment terms') }}: <span
                                class="text-black dark:text-white">{{
                                    __(invoice.term?.replace('_', ' ')?.capitalize()) || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Type of shipment') }}: <span
                                class="text-black dark:text-white">{{
                                    __(invoice.delivery_format?.capitalize()) || '---' }}</span></p>
                                           <p class="text-gray-70">{{ __('Reference to') }}: <span class="text-black dark:text-white">{{
                            invoice.reference?.name || '---'
                                }}</span></p>
                                      <p class="text-gray-70">{{ __('Reference code') }}: <span class="text-black dark:text-white">{{
                            invoice.reference_code || '---'
                                }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Products') }}
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-xs md:table-sm xl:table-md">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th> {{ __('Title') }} </th>
                                    <th> {{ __('sales price') }} </th>
                                    <th> {{ __('Count') }} </th>
                                    <th> {{ __('Discount') }} </th>
                                    <th> {{ __('Total') }} </th>
                                    <th> {{ __('Action') }} </th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in invoice.items">
                                    <td> {{ index + 1 }} </td>
                                    <td> {{ item.title }} </td>
                                    <td> {{ item.sale_price.currency() }} </td>
                                    <td> {{ item.quantity + ' ' + __('Count') }} </td>
                                    <td> {{ calcDiscount(item).currency() }} </td>
                                    <td> {{ ((item.sale_price - calcDiscount(item)) * item.quantity).currency()
                                        }} </td>
                                    <td>
                                        <router-link :to="{ name: 'user.payment.show', params: { id: item.id } }"
                                            target="_blank"
                                            :class="{ 'btn-disabled': !can('payment.view.all') && !can('payment.view.self', item.self) }"
                                            class="btn btn-outline btn-primary btn-sm">
                                            {{ __('Show') }}
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="text-center" v-if="!invoice.items.length">
                                <tr>
                                    <td colspan="6">{{ __('No product selected.') }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div class="divider h-auto col-span-full"></div>
                    <div class="overflow-x-auto col-span-full">
                        <table class="table table-xs md:table-sm xl:table-md">
                            <thead>
                                <tr>
                                    <th> {{ __('Total Amount') }} </th>
                                    <th> {{ __('Discount') }} </th>
                                    <th> {{ __('Shipping cost') }} </th>
                                    <th> {{ __('Tax') }} </th>
                                    <th> {{ __('Deposit amount') }} </th>
                                    <th> {{ __('Other additions') }} </th>
                                    <th> {{ __('Final amount') }} </th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td> {{ invoice.total?.currency() }} </td>
                                    <td> {{ getDiscount()?.currency() }} </td>
                                    <td> {{ invoice.shipping_cost?.currency() }} </td>
                                    <td> {{ invoice.tax?.currency() }} </td>
                                    <td> {{ invoice.deposit_amount?.currency() }} </td>
                                    <td> {{ invoice.miscellaneous_additions?.currency() }} </td>
                                    <td> {{ invoice.amount?.currency() }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Payments') }}
                </div>
                <div class="collapse-content">
                    <div class=" overflow-x-auto">
                    <table class="table table-xs md:table-sm xl:table-md" v-if="invoice.payments.length">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th> {{ __('Title') }} </th>
                                <th> {{ __('Type') }} </th>
                                <th> {{ __('sales price') }} </th>
                                <th> {{ __('Status') }} </th>
                                <th> {{ __('User') }} </th>
                                <th> {{ __('Created At') }} </th>
                                <th> {{ __('Action') }} </th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in invoice.payments">
                                <td> {{ index + 1 }} </td>
                                <td> {{ item.title }} </td>
                                <td> {{ __(item.pay_type.capitalize()) }} </td>
                                <td> {{ item.total.currency() }} </td>
                                <td :class="{ 'text-success': item.status == 'paid','text-error': item.status == 'cancelled'}"> {{ __(item.status.capitalize()) }} </td>
                                <td> {{ item.user || '---' }} </td>
                                <td> {{ item.created_at }}</td>
                                <td>
                                    <router-link :to="{name: 'user.payment.show',params: {id: item.id}}" :class="{'btn-disabled': !can('payment.view')}"
                                        class="btn btn-outline btn-primary btn-sm">
                                        {{ __('Show') }}
                                    </router-link>
                                    <router-link :to="{name: 'user.payment.edit',params: {id:item.id}}" :class="{'btn-disabled': !can('payment.edit.all') && !can('payment.edit.self',item.self)}"
                                        class="btn btn-outline btn-info btn-sm mx-2">
                                        {{ __('Edit') }}
                                    </router-link>
                                    <label @click="setItem(item)" for="delete-modal" :class="{'btn-disabled': !can('payment.delete.all') && !can('payment.delete.self',item.self)}"
                                        class="btn btn-outline btn-error btn-sm">
                                        {{ __('Delete') }}
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-span-12 flex flex-col items-center mt-9" v-else>
                        <NotFound />
                    </div>
                </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Address') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Country') }}: <span class="text-black dark:text-white">{{
                            invoice.billing.country || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('State') }}: <span class="text-black dark:text-white">{{
                            invoice.billing.state || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('City') }}: <span class="text-black dark:text-white">{{
                            invoice.billing.city || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Postal Code') }}: <span class="text-black dark:text-white">{{
                            invoice.billing.postalcode || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Address') }}: <span class="text-black dark:text-white">{{
                            invoice.billing.street || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Latitude') }}: <span class="text-black dark:text-white">{{
                            invoice.billing.latitude || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Longitude') }}: <span class="text-black dark:text-white">{{
                            invoice.billing.longitude || '---' }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Appendices') }}
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-xs md:table-sm xl:table-md">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{ __('File title') }}</th>
                                    <th>{{ __('File type') }}</th>
                                    <th>{{ __('Status') }}</th>
                                    <th>{{ __('Action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in invoice.attachments">
                                    <th>{{ index + 1 }}</th>
                                    <th>{{ item.name }}</th>
                                    <td>{{ item.mime_type }}</td>
                                    <td>
                                        {{ __(item.url ? 'Uploaded' : 'Uploading') }}
                                    </td>
                                    <td>
                                        <a :href="item.url" target="_blank" class="btn btn-outline btn-info btn-sm">
                                            {{ __('Show') }}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="text-center" v-if="!invoice.attachments.length">
                                <tr>
                                    <td colspan="5">{{ __('No file found to display') }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete invoice') }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this invoice?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal"
                        :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                            __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removeInvoice"
                        :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>
</template>
<script setup>
import useDashboard from "../../composables/dashboard";
import { computed, onMounted, reactive, ref } from 'vue';
const { getInvoice, invoice, route, loading, meta, errors, remove, removeInvoice, loadingBtn } = useDashboard();
const setItem = (item) => Object.assign(remove, { id: item.id, single: true });
const calcDiscount = (item) => {
    let amount = item.discount;
    if (item.discount && item.discount_type == 'percent') amount = item.discount * item.sale_price / 100;
    return amount;
}
const getDiscount = () => invoice.items.reduce((carry, item) => carry + (calcDiscount(item) * item.quantity), 0);
const getTotal = () => invoice.items.reduce((carry, item) => carry + (item.sale_price * item.quantity), 0);
const getAmount = () => invoice.items.reduce((carry, item) => {
    return carry + parseInt((item.sale_price - calcDiscount(item)) * item.quantity)
}, 0) + parseInt(invoice.shipping_cost || 0) + parseInt(invoice.tax || 0) + parseInt(invoice.deposit_amount || 0) + parseInt(invoice.miscellaneous_additions || 0);
const getData = () => {
    getInvoice();
}
onMounted(() => {
    getData();
});
</script>