import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useOther(navigation) {
  const settings = reactive({});
  const calendar = ref([]);
  const states = ref([]);
  const cities = ref([]);
  const statistic = reactive({invoice_chart: [],sale_chart:[],user_chart: [],product_chart: []});
  const message = ref(null);
  let timeout = null;
  const getSettings = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/settings");
      loading.value = false;
      Object.assign(settings, {
        ...response.data.data,
        phone: response.data.phone,
      });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const updateSettings = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/settings", settings);
      progressDialog.value = false;
      toast.success(response.data.message);
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const getCalendar = async (date) => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/calendar?date=" + date);
      loading.value = false;
      calendar.value = response.data.data;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getStates = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.get("city");
      loading.value = false;
      loadingBtn.value = response.data.data;
    } catch (e) {
      loadingBtn.value = false;
    }
  };

  const getCities = async (state,value = '') => {
    try {
      loadingBtn.value = true;
      let response = await axios.get("city/" + state);
      loadingBtn.value = false;
      cities.value = response.data.data;
      if (state && meta.states.indexOf((item) => item.name == state) == -1) meta.states.push({ name: state });
      if (value && cities.value.indexOf((item) => item.name == value) == -1)cities.value.push({ name: user.billing.city });
    } catch (e) {
      loadingBtn.value = false;
    }
  };

  const getValues = async (type, query = {}) => {
    try {
      loadingBtn.value = true;
      const saerch = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/searchable/" + type + saerch);
      loadingBtn.value = false;
      meta.type = type;
      meta[type] = response.data.data;
    } catch (e) {
      loadingBtn.value = false;
    }
  };

  const getStatistics = async (type,query = {}) => {
    try {
      loading.value = true;
      const saerch = isObjectNotEmpty(query)
      ? "?" + new URLSearchParams(query).toString()
      : "";
      let response = await axios.get("user/statistic/" + type + saerch);
      loading.value = false;
      Object.assign(statistic,response.data)
    } catch (e) {
      loading.value = false;
    }
  };

  const fetchOptions = (query = {}, type) => {
    loadingBtn.value = false;
    if (timeout) clearTimeout(timeout);
    if (!query.search) return;
    meta.type = type;
    loadingBtn.value = true;
    timeout = setTimeout(() => getValues(type, query), 500);
}

  const checkNumber = async (model = "user", event, id = null) => {
    try {
      const value = event.target.value;
      if (!value || value.length != 11) {
        message.value = "";
        return;
      }
      let response = await axios.post("user/" + model + "/number", {
        value,
        id,
      });
      message.value = response.data?.message;
    } catch (e) {}
  };

  return {
    settings,
    calendar,
    message,
    states,
    cities,
    statistic,
    getSettings,
    updateSettings,
    getCalendar,
    getStates,
    getCities,
    getValues,
    fetchOptions,
    checkNumber,
    getStatistics,
  };
}
