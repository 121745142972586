<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!person.name && errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <section class="lg:grid lg:grid-cols-24 lg:gap-6 flex flex-col">
                <div class="xl:col-span-6 col-span-8 lg:mb-0 mb-5">
                    <ul class="text-lg hidden lg:block">
                        <li class="mb-2 last:mb-0">
                            <button
                                :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 1 }"
                                @click="setStep($event, 1)"
                                class="px-6 w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300 group  border-2 bg-white dark:bg-base-300 dark:border-transparent  rounded-2xl flex items-center">
                                <span :class="{ '!bg-blue-700 !bg-opacity-100': step == 1 }"
                                    class="w-9 bg-gray-350 group-hover:bg-opacity-100 transition duration-200 h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <InformationCircleIcon class="w-5 h-5 text-white" />
                                 </span>
                                {{ __('personal information') }}
                            </button>
                        </li>
                        <li class="mb-2 last:mb-0">
                            <button
                                :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 2 }"
                                @click="setStep($event, 2)"
                                class="px-6  w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300  group groupbg-white dark:bg-base-300 dark:border-transparent border-2 rounded-2xl  flex items-center">
                                <span
                                    :class="{ '!bg-blue-700 !bg-opacity-100': step == 2, 'group-hover:bg-opacity-100': step > 2 }"
                                    class="w-9 bg-gray-350 transition duration-200  h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <ClipboardDocumentListIcon class="w-5 h-5 text-white" />
                                </span>
                                {{ __('More information') }}
                            </button>
                        </li>
                        <li class="mb-2 last:mb-0">
                            <button
                                :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 3 }"
                                @click="setStep($event, 3)"
                                class="px-6  w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300  group group bg-white dark:bg-base-300 dark:border-transparent border-2 rounded-2xl  flex items-center">
                                <span
                                    :class="{ '!bg-blue-700 !bg-opacity-100': step == 3, 'group-hover:bg-opacity-100': step > 3 }"
                                    class="w-9 bg-gray-350 transition duration-200  h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <MapIcon class="w-5 h-5 text-white" />
                                 </span>
                                 {{ __('Address') }}
                            </button>
                        </li>
                        <li class="mb-2 last:mb-0">
                            <button
                                :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 4 }"
                                @click="setStep($event, 4)"
                                class="px-6  w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300  group group bg-white dark:bg-base-300 dark:border-transparent rounded-2xl border-2  flex items-center">
                                <span
                                    :class="{ '!bg-blue-700 !bg-opacity-100': step == 4, 'group-hover:bg-opacity-100': step > 4 }"
                                    class="w-9 bg-gray-350 transition duration-200  h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <ChatBubbleOvalLeftEllipsisIcon class="w-5 h-5 text-white" />
                                </span>
                                {{ __('Social') }}
                            </button>
                        </li>
                        <li class="mb-2 last:mb-0">
                            <button :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 5 }"
                                @click="setStep($event, 5)"
                                class="px-6  w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300  group group bg-white dark:bg-base-300 dark:border-transparent  rounded-2xl border-2  flex items-center">
                                <span
                                    :class="{ '!bg-blue-700 !bg-opacity-100': step == 5, 'group-hover:bg-opacity-100': step > 5 }"
                                    class="w-9 bg-gray-350 transition duration-200  h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <CloudArrowUpIcon class="w-5 h-5 text-white" />
                                </span>
                                {{ __('Appendices') }}
                            </button>
                        </li>
                    </ul>
                    <div class="relative md:hidden group mb-6 w-full" id="courses-page">
                        <div
                            class="dark:bg-base-300 bg-white cursor-pointer dark:text-white text-blue-700  py-3 flex items-center justify-between px-5 h-16 rounded">
                            <span class="flex font-medium text-lg items-center">
                                <span class="mr-2">{{ state }}</span>
                            </span>
                            <span class="border-r h-full flex items-center pr-5 border-blue-700 border-opacity-30 ">
                                <InformationCircleIcon class="w-5 h-5 text-white" />
                            </span>
                        </div>
                        <div class="absolute z-40 hidden group-hover:inline-block top-full pt-3 w-full">
                            <ul class="bg-white dark:bg-base-300  px-2 space-y-2 py-3 rounded">
                                <li>
                                    <button @click="setStep($event, 1)"
                                        :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': step === 1 }"
                                        class="flex w-full items-center font-medium  text-chambray-400 pt-1  h-11 px-5 rounded-lg">
                                        <ClipboardDocumentListIcon class="w-5 h-5 text-white ml-2 -mt-1" />
                                        {{ __('personal information') }}
                                    </button>
                                </li>
                                <li>
                                    <button @click="setStep($event, 2)"
                                        :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': step === 2 }"
                                        class="flex items-center w-full font-medium text-chambray-400 pt-1 h-11 px-5 rounded-lg">
                                        <ChatBubbleOvalLeftEllipsisIcon class="w-5 h-5 text-white ml-2 -mt-1" />
                                        {{ __('More information') }}
                                    </button>
                                </li>
                                <li>
                                    <button @click="setStep($event, 3)"
                                        :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': step === 3 }"
                                        class="flex items-center w-full font-medium text-chambray-400 pt-1 h-11 px-5 rounded-lg">
                                        <MapIcon class="w-5 h-5 text-white ml-2 -mt-1" />
                                        {{ __('Address') }}
                                    </button>
                                </li>
                                <li>
                                    <button @click="setStep($event, 4)"
                                        :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': step === 4 }"
                                        class="flex items-center w-full font-medium text-chambray-400 pt-1 h-11 px-5 rounded-lg">
                                        <ChatBubbleOvalLeftEllipsisIcon class="w-5 h-5 text-white ml-2 -mt-1" />
                                        {{ __('Social') }}
                                    </button>
                                </li>
                                <li>
                                    <button @click="setStep($event, 5)"
                                        :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': step === 5 }"
                                        class="flex items-center w-full font-medium text-chambray-400 pt-1 h-11 px-5 rounded-lg">
                                        <CloudArrowUpIcon class="w-5 h-5 text-white ml-2 -mt-1" />
                                        {{ __('Appendices') }}
                                    </button>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div class="xl:col-span-18 col-span-16">
                    <h2 class="text-3xl font-bold mb-3">{{ $route.params.id ? __('Edit person') : __('Create person') }}</h2>
                    <div class="bg-white dark:bg-base-300 rounded-2xl md:px-6 px-3 sm:pb-6 sm:pt-4 py-2">
                        <Person :person="person" :meta="meta" :step="step" :state="state" />
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import useDashboard from '../../composables/dashboard';
import Person from '../form/Person.vue';
import { MapIcon,CloudArrowUpIcon,InformationCircleIcon,ChatBubbleOvalLeftEllipsisIcon,ClipboardDocumentListIcon } from "@heroicons/vue/24/outline";
const step = ref(1);
const state = ref(window.i18n['personal information']);
const { person, meta, loading,addPerson,editPerson, errors,route } = useDashboard();
const setStep = (e, value) => {
    step.value = value;
    state.value = e.target.textContent.trim();
};
const getData = () => {
    if(route.params.id)editPerson();
    else addPerson();
}
onMounted(() => getData());
</script>