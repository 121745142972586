import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useAppointment(navigation) {
  const { route, router } = navigation;
  const appointments = ref([]);
  const appointment = reactive({
    invitees: [],
    parent_type: "account",
    attachments: [],
  });
  const getAppointments = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      query.type = route.path.split("/").pop();
      const saerch = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/appointment" + saerch);
      loading.value = false;
      appointments.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getAppointmentInvites = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/appointment/invite/users" + saerch);
      loading.value = false;
      users.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getAppointment = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/appointment/" + route.params.id);
      loading.value = false;
      Object.assign(appointment, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addAppointment = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/appointment/create");
      loading.value = false;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editAppointment = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get(
        "user/appointment/" + route.params.id + "/edit"
      );
      loading.value = false;
      Object.assign(appointment, response.data.data);
      Object.assign(meta,{[appointment.parent_type] : [appointment.model]});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeAppointment = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/appointment", appointment);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({ name: "user." + appointment.type + "s" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateAppointment = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put(
        "user/appointment/" + route.params.id,
        appointment
      );
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user." + appointment.type + "s" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeAppointment = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete(
        "user/appointment/" + remove.id + "?type=" + remove.type
      );
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if (remove.single) {
        if (history.state.back) router.back();
        else router.push({ name: "user." + appointment.type + "s" });
      } else
        getAppointments(Object.assign(route.query, { type: appointment.type }));
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  return {
    appointments,
    appointment,
    getAppointmentInvites,
    getAppointments,
    getAppointment,
    addAppointment,
    editAppointment,
    storeAppointment,
    updateAppointment,
    removeAppointment,
  };
}
