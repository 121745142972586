import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useProductCategory(navigation) {
  const { route, router } = navigation;
  const productCategories = ref([]);
  const productCategory = reactive({
    status: "active",
  });
  const getProductCategories = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/product-category" + saerch);
      loading.value = false;
      productCategories.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getProductCategory = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get(
        "user/product-category/" + route.params.id
      );
      loading.value = false;
      Object.assign(productCategory, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addProductCategory = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/product-category/create");
      loading.value = false;
      Object.assign(meta, { ...response.data });
      if(route.query.p_id) productCategory.parent_id = route.query.p_id
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editProductCategory = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get(
        "user/product-category/" + route.params.id + "/edit"
      );
      loading.value = false;
      Object.assign(productCategory, response.data.data);
      Object.assign(meta, { ...response.data });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeProductCategory = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/product-category", productCategory);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({ name: "user.product.categories" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateProductCategory = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put(
        "user/product-category/" + route.params.id,
        productCategory
      );
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user.product.categories" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeProductCategory = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/product-category/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if (remove.single) {
        if (history.state.back) router.back();
        else router.push({ name: "user.product.categories" });
      } else getProductCategories(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  return {
    productCategories,
    productCategory,
    getProductCategories,
    getProductCategory,
    addProductCategory,
    editProductCategory,
    storeProductCategory,
    updateProductCategory,
    removeProductCategory,
  };
}
