import lodash from "lodash";
import axios from "axios";
import { reactive, ref } from "vue";
import router from "./router";
import { useToast } from "vue-toastification";
import Auth from "./composables/auth";
import useHome from "./composables/home";
window.APP_URL =
  process.env.NODE_ENV === "production"
    ? import.meta.env.VITE_APP_URL
    : import.meta.env.VITE_APP_DEBUG_URL;
window._ = lodash;
window.axios = axios;
const { setTheme } = useHome();
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
String.prototype.capitalize = function () {
  return this.replace(/^./, function (match) {
    return match.toUpperCase();
  });
};
String.prototype.currency = function (type = 'toman') {
  const value = this || 0;
  return /^-?\d+$/.test(value) ? parseInt(value).toLocaleString() + ' ' + window.i18n[type.capitalize()] : value;
};
Number.prototype.currency = function (type = 'toman') {
  return (this || 0).toLocaleString() + ' ' + window.i18n[type.capitalize()];
};
window.isObjectNotEmpty = (objectName) => {
  return Object.keys(objectName).length > 0
}
window.setCurrency = (value,currency = '') => {
  return /^-?\d+$/.test(value) ? parseInt(value).toLocaleString() + ' ' + currency : value;
}
window.head = null;
window.seo = null;
window.progressDialog = ref(false);
window.cart = reactive({ items: [] });
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.baseURL = window.APP_URL + "/api/";
window.axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const toast = useToast();
    if (error.response?.status === 401) {
      if (Auth.check()) {
        toast.error(window.i18n["Your Token has expired"]);
        Auth.removeUser();
      }
      router.push({ name: "login" });
    } else if (error.response?.status === 403) router.push({ name: "unauthorized" });
    else if (error.response?.status === 422 && error.response.data.errors)
      toast.error(getErrors(error)[0]);
    return Promise.reject(error);
  }
);
window.setQuery = (router, route, key, value) => {
  let obj = Object.assign({}, route.query);
  if (
    value.length === 0 ||
    ["id", "all", "latest", "1", "unread"].includes(value) ||
    (key !== "search" && value.includes(obj[key]))
  )
    delete obj[key];
  else obj[key] = value;
  router.replace({
    ...router.currentRoute,
    query: obj,
  });
  return obj;
};
window.isPermission = (permission) => {
  const permissions = Array.isArray(permission) ? permission : [permission];
  return permissions.filter(item => Auth.user.value.permissions?.includes(item)).length;
}
window.scrollToBottom = (id = null) => {
  setTimeout(() => {
    const elm = document.getElementById(id);
    if(elm) elm.scrollTop = elm.scrollHeight - elm.clientHeight;
    else window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
  })
  },200);
}
window.isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
window.setSeo = (meta) => {
  const data = { ogTitle: meta.title, twitterTitle: meta.title };
  if (meta.description)
    Object.assign(data, {
      description: meta.description,
      ogDescription: meta.description,
      twitterDescription: meta.description,
    });
  if (meta.image)
    Object.assign(data, { ogImage: meta.image, twitterImage: meta.image });
  return data;
};
window
  .matchMedia("(prefers-color-scheme: dark)")
  .addEventListener("change", (event) => {
    setTheme();
  });
setTheme();
const getErrors = (error) =>
  [].concat(...Object.values(error.response.data.errors));
window.networkError = (error) => {
  if (process.env.NODE_ENV !== "production") console.log(error?.response);
  const toast = useToast();
  const status = error.response?.status;
  if ([0, 500].includes(status))
    toast.error(window.i18n["Error connecting to the server!"]);
};
// document.addEventListener('contextmenu', event => event.preventDefault());

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
