<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!product.title && errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex justify-between items-center mb-6">
                <h3 class="flex items-center md:text-26 text-2xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Product details') }}
                </h3>
                <div class="flex justify-end order-first lg:order-last">
                    <router-link :to="{ name: 'user.product.edit', params: { id: product.id } }"
                        class="btn btn-outline btn-info btn-sm"
                        :class="{ 'btn-disabled': !can('product.edit.all') && !can('product.edit.self', product.self) }">
                        {{ __('Edit') }}
                    </router-link>
                    <label @click="setItem(product)" for="delete-modal"
                        :class="{ 'btn-disabled': !can('product.delete.all') && !can('product.delete.self', product.self) }"
                        class="btn btn-outline btn-error btn-sm mr-2">
                        {{ __('Delete') }}
                    </label>
                </div>
            </div>
            <div class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse-plus collapse">
                <input type="checkbox" checked />
                <div class="collapse-title text-xl font-medium">
                    {{ __('personal information') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Product name') }}: <span class="text-black dark:text-white">{{product.title}}</span></p>
                        <p class="text-gray-70">{{ __('Status') }}: <span :class="[product.status == 'active' ? 'text-success' : 'text-error']">{{
                            __(product.status?.capitalize())
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Type') }}: <span class="text-black dark:text-white">{{
                            __(product.type?.capitalize())
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Unit of measurement') }}: <span class="text-black dark:text-white">{{
                            __(product.main_usage_unit?.capitalize()) }}</span></p>
                        <p class="text-gray-70">{{ __('sales price') }}: <span class="text-black dark:text-white">{{
                            product.sale_price?.currency()
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Purchase price') }}: <span class="text-black dark:text-white">{{
                            product.regular_price ? product.regular_price?.currency() : '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Product Id') }}: <span class="text-black dark:text-white">{{
                            product.part_number || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Product Category') }}: <span class="text-black dark:text-white">{{
                            product.category_name || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Cost calculation type') }}: <span class="text-black dark:text-white">{{
                            __(product.service_cost?.capitalize()) || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Secondary unit of measurement') }}: <span class="text-black dark:text-white">{{
                            __(product.secondary_usage_unit?.capitalize()) || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Number per unit') }}: <span class="text-black dark:text-white">{{
                            product.quantity_per_main_unit || '---' }}</span></p>
                            <p class="text-gray-70">{{ __('In stock') }}: <span class="text-black dark:text-white">{{
                            product.total_inventory || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('User') }}: <span class="text-black dark:text-white">{{
                            product.user }}</span></p>
                        <p class="text-gray-70">{{ __('Created At') }}: <span class="text-black dark:text-white">{{
                            product.created_at }}</span></p>
                        <p class="text-gray-70">{{ __('Update date') }}: <span class="text-black dark:text-white">{{
                            product.updated_at }}</span></p>
                        <div class="divider h-auto col-span-full my-0"></div>
                        <p class="text-gray-70">{{ __('Description') }}: <span class="text-black dark:text-white">{{
                            product.description || '---' }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('More information') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Brand') }}: <span class="text-black dark:text-white">{{
                            product.brand || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Sku') }}: <span class="text-black dark:text-white">{{
                            product.sku || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Serial number') }}: <span class="text-black dark:text-white">{{
                            product.serial_number || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Expiration date') }}: <span class="text-black dark:text-white">{{
                            product.expiration_date || '---' }}</span></p>
                        <p class="text-gray-70 truncate">{{ __('Internet address') }}: <a target="_blank" :class="[product.introduction_link ? 'text-blue-500' : 'text-black dark:text-white']" :href="product.introduction_link">{{
                            product.introduction_link || '---' }}</a></p>
                        <p class="text-gray-70">{{ __('Type of invoice') }}: <span class="text-black dark:text-white">{{
                            (product.invoicing_type?.capitalize()) || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Unit of measurement conversion rate') }}: <span class="text-black dark:text-white">{{
                            product.units_ratio || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Order quantity') }}: <span class="text-black dark:text-white">{{
                            product.quantity_in_demand || '---' }}</span></p>
                               <p class="text-gray-70">{{ __('Sample inventory') }}: <span class="text-black dark:text-white">{{
                            product.sample_quantity || '---' }}</span></p>
                                           <p class="text-gray-70">{{ __('Sellable') }}: <span class="text-black dark:text-white">{{
                            __(product.sellable ? 'Yes' : 'No') }}</span></p>
                                           <p class="text-gray-70">{{ __('Required return order') }}: <span class="text-black dark:text-white">{{
                            __(product.require_return_order ? 'Yes' : 'No') }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Appendices') }}
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-xs md:table-sm xl:table-md">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{ __('File title') }}</th>
                                    <th>{{ __('File type') }}</th>
                                    <th>{{ __('Status') }}</th>
                                    <th>{{ __('Action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in product.attachments">
                                    <th>{{ index + 1 }}</th>
                                    <th>{{ item.name }}</th>
                                    <td>{{ item.mime_type }}</td>
                                    <td>
                                        {{ __(item.url ? 'Uploaded' : 'Uploading') }}
                                    </td>
                                    <td>
                                        <a :href="item.url" target="_blank" class="btn btn-outline btn-info btn-sm">
                                            {{ __('Show') }}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="text-center" v-if="!product.attachments.length">
                                <tr>
                                    <td colspan="5">{{ __('No file found to display') }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete product') }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this product?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal"
                        :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                            __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removeProduct"
                        :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>
</template>
<script setup>
import useDashboard from "../../composables/dashboard";
import { computed, onMounted, reactive, ref } from 'vue';
const { getProduct, product, route, loading, meta, errors, remove, removeProduct, loadingBtn } = useDashboard();
const setItem = (item) => Object.assign(remove, { id: item.id, single: true });
const getData = () => {
    getProduct();
}
onMounted(() => {
    getData();
});
</script>