// shared.js
import { ref,reactive } from "vue";
import { useToast } from "vue-toastification";
export const loading = ref(false);
export const step = ref(1);
export const loadingBtn = ref(false);
export const meta = reactive({});
export const errors = ref("");
export const toast = useToast();
export const remove = reactive({});
export const controller = ref(null);