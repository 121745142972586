<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex justify-between items-center mb-6">
                <h3 class="flex items-center md:text-26 text-xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Ticket details') }}
                </h3>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm sm:px-6 sm:py-6 px-3 py-4  my-5">

                <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                    <p class="text-gray-70">{{ __('Subject') }}: <span class="text-black dark:text-white">{{
                        ticket.subject }}</span></p>
                    <p class="text-gray-70">{{ __('Department') }}: <span class="text-black dark:text-white">{{
                        ticket.department }}</span></p>
                    <p class="text-gray-70">{{ __('Priority') }}: <span class="text-black dark:text-white">{{
                        __(ticket.priority?.capitalize()) }}</span></p>
                    <p>{{ __('Status') }}: <span
                            :class="{ 'text-info': ticket.status == 'open', 'text-warning': ticket.status == 'progress', 'text-success': ticket.status == 'answered', 'text-danger': ticket.status == 'closed' }">{{
                                __(ticket.status?.capitalize()) }}</span></p>
                    <p class="text-gray-70">{{ __('User') }}: <span class="text-black dark:text-white">{{ ticket.user
                            }}</span></p>
                    <p class="text-gray-70">{{ __('Created At') }}: <span class="text-black dark:text-white">{{
                        ticket.created_at }}</span></p>
                    <p class="text-gray-70">{{ __('Last update') }}: <span class="text-black dark:text-white">{{
                        ticket.updated_at }}</span></p>
                </div>
                <div class="divider"></div>
                <div class="overflow-y-auto h-96 scrollbar-small px-3 lg:px-8" id="chat-container">
                    <div class="flex flex-col mt-5" v-for="message in ticket.messages">
                        <div class="chat mt-3" :class="[message.type == 'admin' ? 'chat-end' : 'chat-start']">
                            <div class="chat-bubble md:min-w-64"
                                :class="[message.type == 'admin' ? 'chat-bubble-success text-white' : '']">
                                {{ message.message }}
                                <div class="flex flex-wrap gap-2 mt-2" v-if="message.attachments.length">
                                    <a class="btn btn-ghost btn-outline btn-sm max-w-44 truncate"
                                        :class="{ 'text-gray-50': message.type == 'admin' }" :href="attachment.url"
                                        v-for="attachment in message.attachments">{{ attachment.name }}</a>
                                </div>
                                <p class="text-sm text-end mt-3">{{ message.created_at }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
                <Form @submit="onSubmit" v-slot="{ errors }" class="flex flex-col gap-y-4 mt-5">
                    <div class="flex flex-col">
                        <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                            {{ __('Description') }} <span class="text-error">*</span>
                        </label>
                        <Field as="textarea" name="message" :class="{ 'textarea-error': errors.message }"
                            rules="required" class="textarea min-h-32" v-model="ticket.message" />
                    </div>
                    <div class="flex flex-col col-span-full">
                        <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                            {{ __('File') }}
                        </label>
                        <Field class="file-input pr-0" @change="addAttachment($event, 'attachment')" type="file"
                            name="file" />
                    </div>
                    <div class="overflow-x-auto col-span-full" v-if="ticket.attachments?.length">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{ __('File title') }}</th>
                                    <th>{{ __('File type') }}</th>
                                    <th>{{ __('Status') }}</th>
                                    <th>{{ __('Action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in ticket.attachments">
                                    <th>{{ index + 1 }}</th>
                                    <th>{{ item.name }}</th>
                                    <td>{{ item.mime_type }}</td>
                                    <td>
                                        <div v-if="isNumeric(item.status)">
                                            <progress class="progress progress-info w-16" :value="item.status"
                                                max="100"></progress> <span class="mr-0.5 inline-block w-4">{{
                                                    item.status +
                                                    '%' }}</span>
                                        </div>
                                        <div v-else>{{ __(item.url ? 'Uploaded' : 'Uploading') }}</div>
                                    </td>
                                    <td>
                                        <button @click="removeFile(ticket.attachments, index)" type="button"
                                            class="btn btn-outline btn-error btn-sm">
                                            {{ __('Delete') }}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="divider h-auto w-full"></div>
                    <div class="text-end">
                        <button type="submit" class="btn btn-primary px-6 mr-2">{{
                            __('send Message')
                            }}</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>

</template>
<script setup>
import useDashboard from "../../composables/dashboard";
import { Field, Form } from "vee-validate";
import { computed, onMounted } from 'vue';
const { getTicket, ticket, loading, meta, errors, uploadFile, removeFile, storeTicketMessage } = useDashboard();
const getData = () => {
    getTicket();
}
const isNumeric = (value) => window.isNumeric(value);
const addAttachment = (event, type) => {
    ticket.attachments = [...ticket.attachments, { title: event.target.files[0].name, type: 'attachment', status: 'uploading' }];
    uploadFile(event, type, ticket.attachments[ticket.attachments.length - 1]);
}

const onSubmit = () => {
    storeTicketMessage()
}
onMounted(() => {
    getData();
});
</script>