<template>
    <Form @submit="onSubmit" v-slot="{ errors }" @invalid-submit="onInvalidSubmit">
        <div class="flex flex-col">
            <div>
                <div class="mb-10  last:mb-0">
                    <h2 class="text-dark-550  dark:text-white font-bold flex items-center text-xl">
                        <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                        {{ state }}
                    </h2>
                    <div class="divider col-span-full mt-0 mb-3"></div>
                    <div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 1">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Title') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="payment.title" rules="required"
                                    :class="{ 'input-error': errors.title }" class="input" type="text"
                                    name="title" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Amount') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="payment.total" rules="required"
                                    :class="{ 'input-error': errors.total }" class="input" type="number"
                                    name="total" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Pay type') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="payment.pay_type" rules="required"
                                    :class="{ 'input-error': errors.pay_type }" class="select" name="pay_type">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in payTypes" :value="item">{{
                                        __(item.replace('_', ' ').capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Status') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="payment.status" rules="required"
                                    :class="{ 'input-error': errors.status }" class="select" name="status">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in statusTypes" :value="item">{{
                                        __(item.replace('_', ' ').capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Payment date') }}
                                </label>
                                <Field v-model="payment.date" name="date" readonly class="input date-input cursor-pointer text-end" dir="ltr" :class="{'input-error': errors.date}" />
                                <DatePicker v-model="payment.date" custom-input=".date-input" type="date" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Transaction ID') }}
                                </label>
                                <Field v-model="payment.transaction_id" :class="{ 'input-error': errors.transaction_id }"
                                    class="input" type="number" name="transaction_id" />
                            </div>
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Description') }}
                                </label>
                                <Field as="textarea" v-model="payment.description"
                                    :class="{ 'input-error': errors.description }" class="textarea" type="text"
                                    name="description" />
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 2">
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('File') }}
                                </label>
                                <Field class="file-input pr-0" @change="addAttachment($event, 'attachment')" type="file"
                                    name="file" />
                            </div>
                            <div class="overflow-x-auto col-span-full">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{{ __('File title') }}</th>
                                            <th>{{ __('File type') }}</th>
                                            <th>{{ __('Status') }}</th>
                                            <th>{{ __('Action') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in payment.attachments">
                                            <th>{{ index + 1 }}</th>
                                            <th>{{ item.name }}</th>
                                            <td>{{ item.mime_type }}</td>
                                            <td>
                                                <div v-if="isNumeric(item.status)">
                                                    <progress class="progress progress-info w-16" :value="item.status"
                                                        max="100"></progress> <span class="mr-0.5 inline-block w-4">{{
                                                        item.status + '%' }}</span>
                                                </div>
                                                <div v-else>{{ __(item.url ? 'Uploaded' : 'Uploading') }}</div>
                                            </td>
                                            <td>
                                                <button @click="removeFile(payment.attachments, index)" type="button"
                                                    class="btn btn-outline btn-error btn-sm">
                                                    {{ __('Delete') }}
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="text-center" v-if="!payment.attachments.length">
                                        <tr>
                                            <td colspan="5">{{ __('No file found to display') }}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider h-auto w-full"></div>
            <div class="text-end">
                <button type="submit" class="btn btn-primary px-6 mr-2">{{
                    __('Save Changes')
                    }}</button>
            </div>
        </div>
    </Form>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import useDashboard from '../../composables/dashboard';
import DatePicker from 'vue3-persian-datetime-picker'
import { Field, Form } from "vee-validate";
const props = defineProps(['payment', 'step', 'state']);
const payTypes = ['cash', 'check', 'online', 'wire_transfer','currency_transfer'];
const statusTypes = ['outstanding_balance','agreed_plan', 'cancelled', 'paid'];
const { uploadFile, removeFile, payment,loadingBtn, storePayment, updatePayment,meta, route, loading } = useDashboard();
Object.assign(payment, {...props.payment, parent_type: 'invoice',paymentable_id: props.payment.paymentable_id || route.query.i});
const isNumeric = (value) => window.isNumeric(value);
const addAttachment = (event, type) => {
    payment.attachments = [...payment.attachments, { title: event.target.files[0].name, type: 'attachment', status: 'uploading' }];
    uploadFile(event, type, payment.attachments[payment.attachments.length - 1]);
}
const onSubmit = () => {
    if (route.params.id) updatePayment();
    else storePayment();
}
const onInvalidSubmit = ({ errors }) => {

};
</script>