<template>
    <Form @submit="onSubmit" v-slot="{ errors }" @invalid-submit="onInvalidSubmit">
        <div class="flex flex-col">
            <div>
                <div class="mb-10  last:mb-0">
                    <h2 class="text-dark-550  dark:text-white font-bold flex items-center text-xl">
                        <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                        {{ state }}
                    </h2>
                    <div class="divider col-span-full mt-0 mb-3"></div>
                    <div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 1">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Title') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="invoice.title" rules="required" :class="{ 'input-error': errors.title }"
                                    class="input" type="text" name="title" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Account') }} <span class="text-error">*</span>
                                </label>
                                <Field name="account_id" v-slot="{ field }" rules="required"
                                    :value="invoice.account_id">
                                    <v-select dir="rtl" :options="meta.account" :reduce="(option) => option.id" id="account_id"
                                        v-model="invoice.account_id" :placeholder="__('Select...')" v-bind="field"
                                        class="input" :class="{ 'input-error': errors.account_id }" :filterable="false" :clearable="false"
                                        @search="fetchOptions($event, 'account')" :loading="meta.type == 'account' && loadingBtn" label="name">
                                        <template #no-options="{ search, searching }">
                                            <template v-if="searching">
                                                <div class="opacity-60">{{ __('Nothing found to display') }}.</div>
                                            </template>
                                            <div v-else class="opacity-60">{{ __('Start typing to search')
                                                }}.</div>
                                        </template>
                                    </v-select>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Status') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="invoice.status" :class="{ 'input-error': errors.status }" rules="required"
                                    class="select" name="status">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in statusTypes" :value="item">
                                        {{ __(item?.replace('_', ' ')?.capitalize()) }}
                                    </option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Invoice date') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="invoice.invoice_date" name="invoice_date" rules="required" readonly
                                    class="input date-input cursor-pointer text-end" dir="ltr"
                                    :class="{ 'input-error': errors.invoice_date }" />
                                <DatePicker v-model="invoice.invoice_date" custom-input=".date-input" rules="required"
                                    type="date" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Parent Type') }}
                                </label>
                                <Field as="select" v-model="invoice.parent_type"
                                    :class="{ 'input-error': errors.parent_type }" class="select" name="parent_type">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option value="opportunity">{{ __('Opportunity') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __(invoice.parent_type?.capitalize() || 'User') }}
                                </label>
                                <Field name="invoiceable_id" v-slot="{ field }"
                                    :value="invoice.invoiceable_id">
                                    <v-select dir="rtl" :options="meta[invoice.parent_type]" :reduce="(option) => option.id" id="invoiceable_id"
                                        v-model="invoice.invoiceable_id" :placeholder="__('Select...')" v-bind="field"
                                        class="input" :filterable="false" :clearable="false"
                                        @search="fetchOptions($event,invoice.parent_type)" :loading="meta.type == invoice.parent_type && loadingBtn" label="name">
                                        <template #no-options="{ search, searching }">
                                            <template v-if="searching">
                                                <div class="opacity-60">{{ __('Nothing found to display') }}.</div>
                                            </template>
                                            <div v-else class="opacity-60">{{ __('Start typing to search')
                                                }}.</div>
                                        </template>
                                    </v-select>
                                </Field>
                            </div>
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Description') }}
                                </label>
                                <Field as="textarea" v-model="invoice.description"
                                    :class="{ 'input-error': errors.description }" class="textarea" type="text"
                                    name="description" />
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 2">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Reminder SMS to customer') }}
                                </label>
                                <Field as="select" v-model="invoice.sms_reminder_time"
                                    :class="{ 'input-error': errors.sms_reminder_time }" class="select"
                                    name="sms_reminder_time">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in reminderTimes" :value="item">{{
                                        __( item + ' Minute after') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('How to send goods') }}
                                </label>
                                <Field as="select" v-model="invoice.shipping_type"
                                    :class="{ 'input-error': errors.shipping_type }" class="select"
                                    name="shipping_type">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option :value="item" v-for="item in ShippingTypes">{{ __(item.capitalize()) }}
                                    </option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Payment terms') }}
                                </label>
                                <Field as="select" v-model="invoice.term" :class="{ 'input-error': errors.term }"
                                    class="select" name="term">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option :value="item" v-for="item in termTypes">
                                        {{ __(item.replace('_', ' ').capitalize()) }}
                                    </option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Type of shipment') }}
                                </label>
                                <Field as="select" v-model="invoice.delivery_format"
                                    :class="{ 'input-error': errors.delivery_format }" class="select"
                                    name="delivery_format">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option value="urgent">{{ __('Urgent') }}</option>
                                    <option value="normal">{{ __('Normal') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Reference to') }}
                                </label>
                                <Field as="select" v-model="invoice.reference_id"
                                    :class="{ 'input-error': errors.reference_id }" class="select"
                                    name="reference_id">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option :value="item.id" v-for="item in meta.users">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Reference code') }}
                                </label>
                                <Field v-model="invoice.reference_code" :class="{ 'input-error': errors.reference_code }"
                                    class="input" type="text" name="reference_code" />
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 3">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Product') }}
                                </label>
                                <Field name="product_id" v-slot="{ field }" :value="invoice.product_id">
                                    <v-select dir="rtl" :options="meta.product" :reduce="(option) => option.id" id="product_id"
                                        @option:selected="setItem" v-model="invoice.product_id"
                                        :placeholder="__('Select...')" v-bind="field" class="input" :filterable="false"
                                        :class="{ 'input-error': errors.invoice }"
                                        @search="fetchOptions($event, 'product')" :loading="loadingBtn" label="name">
                                        <template #no-options="{ search, searching }">
                                            <template v-if="searching">
                                                <div class="opacity-60">{{ __('Nothing found to display') }}.</div>
                                            </template>
                                            <div v-else class="opacity-60">{{ __('Start typing to search for a product')
                                                }}.</div>
                                        </template>
                                    </v-select>
                                </Field>
                            </div>
                            <div class="overflow-x-auto col-span-full">
                                <table class="table table-xs md:table-sm xl:table-md">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> {{ __('Title') }} </th>
                                            <th> {{ __('sales price') }} </th>
                                            <th> {{ __('Count') }} </th>
                                            <th> {{ __('Discount') }} </th>
                                            <th> {{ __('Total') }} </th>
                                            <th> {{ __('Action') }} </th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in invoice.items">
                                            <td> {{ index + 1 }} </td>
                                            <td> {{ item.title }} </td>
                                            <td> {{ item.sale_price.currency() }} </td>
                                            <td> {{ item.quantity + ' ' + __('Count') }} </td>
                                            <td> {{ calcDiscount(item).currency() }} </td>
                                            <td> {{ ((item.sale_price - calcDiscount(item)) * item.quantity).currency()
                                                }} </td>
                                            <td>
                                                <router-link
                                                    :to="{ name: 'user.product.show', params: { id: item.id } }"
                                                    target="_blank"
                                                    :class="{ 'btn-disabled': !can('product.view.all') && !can('product.view.self', item.self) }"
                                                    class="btn btn-outline btn-primary btn-sm">
                                                    {{ __('Show') }}
                                                </router-link>
                                                <label @click="setProduct(item)" for="edit-modal"
                                                    class="btn btn-outline btn-info btn-sm mx-2">
                                                    {{ __('Edit') }}
                                                </label>
                                                <button @click="removeProduct(item.id)" type="button"
                                                    class="btn btn-outline btn-error btn-sm">
                                                    {{ __('Delete') }}
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="text-center" v-if="!invoice.items.length">
                                        <tr>
                                            <td colspan="6">{{ __('No product selected.') }}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Shipping Cost') }}
                                </label>
                                <Field v-model="invoice.shipping_cost" :class="{ 'input-error': errors.shipping_cost }"
                                    class="input" type="number" name="shipping_cost" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Tax') }}
                                </label>
                                <Field v-model="invoice.tax" :class="{ 'input-error': errors.tax }" class="input"
                                    type="number" name="tax" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Deposit amount') }}
                                </label>
                                <Field v-model="invoice.deposit_amount"
                                    :class="{ 'input-error': errors.deposit_amount }" class="input" type="number"
                                    name="deposit_amount" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Other additions') }}
                                </label>
                                <Field v-model="invoice.miscellaneous_additions"
                                    :class="{ 'input-error': errors.miscellaneous_additions }" class="input"
                                    type="number" name="miscellaneous_additions" />
                            </div>
                            <div class="overflow-x-auto col-span-full">
                                <table class="table table-xs md:table-sm xl:table-md">
                                    <thead>
                                        <tr>
                                            <th> {{ __('Total Amount') }} </th>
                                            <th> {{ __('Discount') }} </th>
                                            <th> {{ __('Shipping cost') }} </th>
                                            <th> {{ __('Tax') }} </th>
                                            <th> {{ __('Deposit amount') }} </th>
                                            <th> {{ __('Other additions') }} </th>
                                            <th> {{ __('Final amount') }} </th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> {{ getTotal().currency() }} </td>
                                            <td> {{ getDiscount().currency() }} </td>
                                            <td> {{ invoice.shipping_cost.currency() }} </td>
                                            <td> {{ invoice.tax.currency() }} </td>
                                            <td> {{ invoice.deposit_amount.currency() }} </td>
                                            <td> {{ invoice.miscellaneous_additions.currency() }} </td>
                                            <td> {{ getAmount().currency() }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 4">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Country') }}
                                </label>
                                <Field as="select" v-model="invoice.billing.country"
                                    :class="{ 'input-error': errors.billing_country }" class="select"
                                    name="billing_country">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option :value="__('Iran')">{{ __('Iran') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('State') }}
                                </label>
                                <Field as="select" v-model="invoice.billing.state" @change="changeState"
                                    :class="{ 'input-error': errors.billing_state }" class="select"
                                    name="billing_state">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.states" :value="item.name">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('City') }}
                                </label>
                                <Field as="select" v-model="invoice.billing.city" @change="changeCity"
                                    :class="{ 'input-error': errors.billing_city }" class="select" name="billing_city">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in cities" :value="item.name">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Postal Code') }}
                                </label>
                                <Field v-model="invoice.billing.postalcode"
                                    :class="{ 'input-error': errors.billing_postalcode }" class="input" type="number"
                                    name="billing_postalcode" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Address') }}
                                </label>
                                <Field v-model="invoice.billing.street"
                                    :class="{ 'input-error': errors.billing_street }" class="input" type="text"
                                    name="billing_street" />
                            </div>
                            <div class="flex items-end">
                                <div class="flex flex-col flex-1">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Latitude') }}
                                    </label>
                                    <Field v-model="invoice.billing.latitude"
                                        :class="{ 'input-error': errors.billing_latitude }" class="input" type="number"
                                        name="billing_latitude" />
                                </div>
                                <div class="flex flex-col mr-1 flex-1">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Longitude') }}
                                    </label>
                                    <Field v-model="invoice.billing.longitude"
                                        :class="{ 'input-error': errors.billing_longitude }" class="input" type="number"
                                        name="billing_longitude" />
                                </div>
                                <label class="btn btn-primary mr-1" for="modal-map" @click="setMap">
                                    <MapPinIcon class="w-5 h-5" />
                                </label>
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 5">
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('File') }}
                                </label>
                                <Field class="file-input pr-0" @change="addAttachment($event, 'attachment')" type="file"
                                    name="file" />
                            </div>
                            <div class="overflow-x-auto col-span-full">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{{ __('File title') }}</th>
                                            <th>{{ __('File type') }}</th>
                                            <th>{{ __('Status') }}</th>
                                            <th>{{ __('Action') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in invoice.attachments">
                                            <th>{{ index + 1 }}</th>
                                            <th>{{ item.name }}</th>
                                            <td>{{ item.mime_type }}</td>
                                            <td>
                                                <div v-if="isNumeric(item.status)">
                                                    <progress class="progress progress-info w-16" :value="item.status"
                                                        max="100"></progress>
                                                    <span class="mr-0.5 inline-block w-4">{{ item.status + '%' }}</span>
                                                </div>
                                                <div v-else>{{ __(item.url ? 'Uploaded' : 'Uploading') }}</div>
                                            </td>
                                            <td>
                                                <button @click="removeFile(invoice.attachments, index)" type="button"
                                                    class="btn btn-outline btn-error btn-sm">
                                                    {{ __('Delete') }}
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="text-center" v-if="!invoice.attachments.length">
                                        <tr>
                                            <td colspan="5">{{ __('No file found to display') }}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider h-auto w-full"></div>
            <div class="text-end">
                <button type="submit" class="btn btn-primary px-6 mr-2">{{
                    __('Save Changes')
                    }}</button>
            </div>
        </div>
    </Form>
    <input type="checkbox" id="modal-map" class="modal-toggle" />
    <div class="modal">
        <div class="modal-box max-w-5xl">
            <div class="h-[600px]">
                <l-map ref="map" v-model:zoom="zoom" :center="centerLatLng" v-if="showMap" :use-global-leaflet="false"
                    @update:center="updateCenter">
                    <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
                        name="OpenStreetMap"></l-tile-layer>
                    <l-marker :lat-lng="markerLatLng"></l-marker>
                </l-map>
            </div>
            <div class="modal-action justify-between items-center">
                <div>
                    <label class="btn btn-error ml-2 btn-sm" for="modal-map">{{ __('Cancel') }}</label>
                    <label class="btn btn-success btn-sm text-white" for="modal-map" @click="setLocation">{{ __('Save')
                        }}</label>
                </div>
            </div>
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="edit-modal" class="modal-toggle" />
        <div class="modal">
            <div class="modal-box bg-base-300" v-if="update.id">
                <h3 class="font-bold text-lg">{{ __('Edit') + ' ' + update.name }}</h3>
                <Form @submit="updateItem" class="h-full flex flex-col mt-4" v-slot="{ errors }">
                    <div>
                        <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium mt-3" for="">
                            {{ __('sales price') }}
                        </label>
                        <Field name="sale_price" :class="{ 'input-error': errors.sale_price }" class="input"
                            v-model="update.sale_price" />
                    </div>
                    <div>
                        <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium mt-3" for="">
                            {{ __('Count') }}
                        </label>
                        <Field name="quantity" :class="{ 'input-error': errors.quantity }" class="input"
                            v-model="update.quantity" />
                    </div>
                    <div>
                        <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium mt-3" for="">
                            {{ __('Discount type') }}
                        </label>
                        <Field as="select" select name="discount_type" :class="{ 'select-error': errors.discount_type }"
                            class="select" v-model="update.discount_type">
                            <option value="percent">{{ __('Percent') }}</option>
                            <option value="amount">{{ __('Amount') }}</option>
                        </Field>
                    </div>
                    <div>
                        <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium mt-3" for="">
                            {{ __('Discount') }}
                        </label>
                        <Field name="discount" :class="{ 'input-error': errors.discount }" class="input"
                            v-model="update.discount" />
                    </div>
                    <div class="modal-action">
                        <label for="edit-modal" class="btn btn-sm">{{ __('Refuse')
                            }}</label>
                        <button type="submit" class="btn btn-sm btn-info !ml-0 !mr-2">
                            {{ __('Edit') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import useDashboard from '../../composables/dashboard';
import DatePicker from 'vue3-persian-datetime-picker'
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import { Field, Form } from "vee-validate";
import { MapPinIcon } from "@heroicons/vue/24/outline";
const props = defineProps(['invoice', 'step', 'state']);
const showMap = ref(false);
const zoom = 11;
let timeout = null;
const centerLatLng = ref([35.7029075623, 51.3497581482]);
const markerLatLng = ref(centerLatLng.value);
const update = ref({});
const reminderTimes = ['15', '60', '120', '360', '720', '1440'];
const ShippingTypes = ['marine', 'aerial', 'earthly', 'train'];
const termTypes = ['cash', 'installments', 'open_account', 'negotiable'];
const statusTypes = ['not_sent', 'unpaid', 'partially_paid', 'paid', 'canceled', 'rejected'];
const { uploadFile, removeFile, invoice, getCities, storeInvoice, updateInvoice, getValues,meta, route, loading, loadingBtn, cities } = useDashboard();
Object.assign(invoice, props.invoice);
if (invoice.billing.state) getCities(invoice.billing.state,invoice.billing.city);
const updateCenter = (latlng) => markerLatLng.value = [latlng.lat, latlng.lng];
const addAttachment = (event, type) => {
    invoice.attachments = [...invoice.attachments, { title: event.target.files[0].name, type: 'attachment', status: 'uploading' }];
    uploadFile(event, type, invoice.attachments[invoice.attachments.length - 1]);
}
const isNumeric = (value) => window.isNumeric(value);
const changeState = () => {
    invoice.billing.city = '';
    invoice.billing.latitude = '';
    invoice.billing.longitude = '';
    getCities(invoice.billing.state);
}
const changeCity = () => {
    invoice.billing.latitude = '';
    invoice.billing.longitude = '';
}
const setProduct = (product) => update.value = product;
const calcDiscount = (item) => {
    let amount = item.discount;
    if (item.discount && item.discount_type == 'percent') amount = item.discount * item.sale_price / 100;
    return amount;
}
const getDiscount = () => invoice.items.reduce((carry, item) => carry + (calcDiscount(item) * item.quantity), 0);
const getTotal = () => invoice.items.reduce((carry, item) => carry + (item.sale_price * item.quantity), 0);
const getAmount = () => invoice.items.reduce((carry, item) => {
    return carry + parseInt((item.sale_price - calcDiscount(item)) * item.quantity)}, 0) + parseInt(invoice.shipping_cost || 0) + parseInt(invoice.tax || 0) + parseInt(invoice.deposit_amount || 0) + parseInt(invoice.miscellaneous_additions || 0);
const setMap = () => {
    showMap.value = true;
    const billing = invoice.billing;
    if (billing.latitude && billing.longitude) centerLatLng.value = [billing.latitude, billing.longitude];
    else if (cities.value.find(item => billing.city == item.name)) {
        const city = cities.value.find(item => billing.city == item.name);
        centerLatLng.value = [city.latitude, city.longitude];
    } else if (props.meta.states.find(item => billing.state == item.name)) {
        const state = props.meta.states.find(item => billing.state == item.name);
        centerLatLng.value = [state.latitude, state.longitude];
    }
    markerLatLng.value = centerLatLng.value;
}
const updateItem = () => {
    const item = invoice.items.find(item => item.id == update.value.id);
    Object.assign(item, update.value);
    document.getElementById('edit-modal').checked = false;
    update.value = {};
}
const setItem = (product) => {
    const isItem = invoice.items.find(item => item.product_id == product.id);
    if (!isItem) invoice.items.push({ ...product,product_id: product.id,title: product.name, quantity: 1, discount_type: 'percent', discount: 0 });
    invoice.product_id = null;
}

const removeProduct = (productId) => {
    invoice.items = invoice.items.filter(item => item.id != productId);
}

const fetchOptions = (search, type) => {
    loadingBtn.value = false;
    if (timeout) clearTimeout(timeout);
    if (!search) return;
    meta.type = type;
    loadingBtn.value = true;
    timeout = setTimeout(() => getValues(type, { search }), 500);
}
const setLocation = () => Object.assign(invoice.billing, { latitude: markerLatLng.value[0], longitude: markerLatLng.value[1] })
const onSubmit = () => {
    if (route.params.id) updateInvoice();
    else storeInvoice();
}
const onInvalidSubmit = ({ errors }) => {

};
</script>