import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useDiscount(navigation) {
  const { route, router } = navigation;
  const discounts = ref([]);
  const discount = reactive({
    parent_type: "clue",
    // discount_type: 'amount',
    code: Math.random().toString(36).replace(".", "").slice(1, 9),
    status: "active",
  });
  const getDiscounts = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/discount" + search);
      loading.value = false;
      discounts.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editDiscount = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get(
        "user/discount/" + route.params.id + "/edit"
      );
      loading.value = false;
      Object.assign(discount, response.data.data);
      meta.options = [discount.user];
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeDiscount = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/discount", discount);
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user.discounts" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateDiscount = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/discount/" + discount.id, discount);
      const index = industries.value.findIndex(
        (item) => item.id === discount.id
      );
      industries.value[index] = response.data.data;
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user.discounts" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeDiscount = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/discount/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if (remove.single) {
        if (history.state.back) router.back();
        else router.push({ name: "user.discounts" });
      } else getDiscounts(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };
  return {
    discounts,
    discount,
    getDiscounts,
    editDiscount,
    storeDiscount,
    updateDiscount,
    removeDiscount,
  };
}
