import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useCampaign(navigation) {
  const { route, router } = navigation;
  const campaigns = ref([]);
  const campaign = reactive({});
  const getCampaigns = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/campaign" + search);
      loading.value = false;
      campaigns.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getCampaign = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/campaign/" + route.params.id);
      loading.value = false;
      Object.assign(campaign, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addCampaign = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/campaign/create");
      loading.value = false;
      meta.campaigns = response.data.campaigns;
      if (route.query.p_id) campaign.parent_id = route.query.p_id;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editCampaign = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get(
        "user/campaign/" + route.params.id + "/edit"
      );
      loading.value = false;
      Object.assign(campaign, response.data.data);
      meta.campaigns = response.data.campaigns;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeCampaign = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/campaign", campaign);
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user.campaigns" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateCampaign = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/campaign/" + campaign.id, campaign);
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user.campaigns" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeCampaign = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/campaign/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if (remove.single) {
        if (history.state.back) router.back();
        else router.push({ name: "user.campaigns" });
      } else getCampaigns(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };
  return {
    campaigns,
    campaign,
    getCampaigns,
    getCampaign,
    addCampaign,
    editCampaign,
    storeCampaign,
    updateCampaign,
    removeCampaign,
  };
}
