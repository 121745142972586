import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useSaveMessage(navigation) {
  const { route, router } = navigation;
  const saveMessages = ref([]);
  const saveMessage = reactive({ content: "" });
  const getSaveMessages = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/save-message" + search);
      loading.value = false;
      saveMessages.value = response.data.data;
      Object.assign(meta, response.data.meta);
      if (query.per_page)
        document.getElementById("message-modal").checked = true;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeSaveMessage = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/save-message", saveMessage);
      progressDialog.value = false;
      toast.success(response.data.message);
      document.getElementById("add-edit-modal").checked = false;
      getSaveMessages();
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateSaveMessage = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put(
        "user/save-message/" + saveMessage.id,
        saveMessage
      );
      const index = saveMessages.value.findIndex(
        (item) => item.id === saveMessage.id
      );
      saveMessages.value[index] = response.data.data;
      progressDialog.value = false;
      toast.success(response.data.message);
      document.getElementById("add-edit-modal").checked = false;
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeSaveMessage = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/save-message/" + saveMessage.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      getSaveMessages(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  return {
    saveMessages,
    saveMessage,
    getSaveMessages,
    storeSaveMessage,
    updateSaveMessage,
    removeSaveMessage,
  };
}
