import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useAccount(navigation) {
  const accounts = ref([]);
  const account = reactive({
    type: "genuine",
    numbers: [{ type: "office", value: "" }],
    billing: {},
    account_type: "",
    is_foreigner: "0",
    emails: [{ value: "" }],
    credit: {},
    social: {},
    attachments: [],
    status: "new",
  });
  const { route, router } = navigation;
  const getAccounts = async (query = {}) => {
    try {
      errors.value = "";
      if (query.inline) loadingBtn.value = true;
      else loading.value = true;
      const saerch = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/account" + saerch);
      if (query.inline) loadingBtn.value = false;
      else loading.value = false;
      if (query.per_page && query.inline)
        accounts.value.push(...response.data.data);
      else accounts.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e.message;
      if (query.inline) loadingBtn.value = false;
      else loading.value = false;
    }
  };

  const getAccount = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/account/" + route.params.id);
      loading.value = false;
      Object.assign(account, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addAccount = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/account/create");
      loading.value = false;
      Object.assign(meta, { ...response.data });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editAccount = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get(
        "user/account/" + route.params.id + "/edit"
      );
      loading.value = false;
      Object.assign(account, response.data.data);
      Object.assign(meta, { ...response.data });
      if (
        account.industry &&
        meta.industries.indexOf((item) => item.title == account.industry) == -1
      )
        meta.industries.push({ title: account.industry });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeAccount = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/account", account);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({ name: "user.accounts" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateAccount = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put(
        "user/account/" + route.params.id,
        account
      );
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user.accounts" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeAccount = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/account/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if (remove.single) {
        if (history.state.back) router.back();
        else router.push({ name: "user.accounts" });
      } else getAccounts(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };
  return {
    accounts,
    account,
    getAccounts,
    getAccount,
    addAccount,
    editAccount,
    storeAccount,
    updateAccount,
    removeAccount,
  };
}
