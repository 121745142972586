<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <section class="lg:grid lg:grid-cols-24 lg:gap-6 flex flex-col">
                <div class="xl:col-span-18 xl:col-start-4 col-span-20 col-start-3">
                    <h2 class="text-3xl font-bold mb-3">
                        {{ $route.params.id ? __('Edit product category') : __('Add product category')
                        }}
                    </h2>
                    <div class="bg-white dark:bg-base-300 rounded-2xl md:px-6 px-3 sm:pb-6 sm:pt-4 py-2">
                        <Form @submit="onSubmit" v-slot="{ errors }">
                            <div class="grid grid-cols-1 md:gap-x-6 gap-y-4">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Title') }} <span class="text-error">*</span>
                                </label>
                                <Field as="input" name="title" :class="{ 'input-error': errors.title }" rules="required"
                                    class="input" v-model="productCategory.title" />
                            </div>

                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Category') }}
                                </label>
                                <Field as="select" name="parent_id" :class="{ 'select-error': errors.parent_id }"
                                    class="select" v-model="productCategory.parent_id">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.categories" :value="item.id">{{ item.title }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Status') }}
                                </label>
                                <Field as="select" name="status" :class="{ 'select-error': errors.status }"
                                    class="select" v-model="productCategory.status">
                                    <option value="active">{{ __('Active') }}</option>
                                    <option value="inactive">{{ __('Inactive') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Description') }}
                                </label>
                                <Field as="textarea" name="description"
                                    :class="{ 'textarea-error': errors.description }" class="textarea"
                                    v-model="productCategory.description" />
                            </div>
                        </div>
                            <div class="divider h-auto w-full"></div>
                            <div class="text-end">
                                <button type="submit" class="btn btn-primary px-6 mr-2">{{
                                    __('Save Changes')
                                }}</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import useDashboard from '../../composables/dashboard';
import { Field, Form } from "vee-validate";
const { productCategory, storeProductCategory, addProductCategory, editProductCategory, updateProductCategory, loading, meta, errors, route } = useDashboard();
const getData = () => {
    if (route.params.id) editProductCategory();
    else addProductCategory()
}
const onSubmit = () => {
    if (route.params.id) updateProductCategory();
    else storeProductCategory();
}

onMounted(() => {
    getData();
});
</script>