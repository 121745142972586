import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, step, meta, loadingBtn, remove } from "./shared";

export default function useMessage(navigation) {
  const messages = ref([]);
  const sms = reactive({});
  const numbers = ref([]);
  const sendMessage = reactive({
    content: "",
    type: "test",
    date: "immediately",
    shipping_time: "",
    census: "",
    excel: [],
    manual: true,
    block_number: false,
    manual_number: "",
    numbers: [],
    number_count: 0,
    clues: [],
    accounts: [],
  });
  const importContact = async (excel) => {
    try {
      if (!excel.target.files.length) return false;
      errors.value = "";
      loadingBtn.value = true;
      const file = new FormData();
      file.append("excel", excel.target.files[0]);
      let response = await axios.post("user/message/import", file, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      sendMessage.excel = response.data.data;
      loadingBtn.value = false;
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const storeMessage = async () => {
    try {
      errors.value = "";
      loading.value = true;
      if (sendMessage.date == "immediately") sendMessage.shipping_time = "";
      const { content, type, census, shipping_time, manual_number, excel } =
        sendMessage;
      const manual =
        type == "test" || sendMessage.manual ? manual_number.split("\n") : [];
      manual.push(...excel);
      let response = await axios.post("user/message", {
        content,
        type,
        census,
        shipping_time,
        manual,
        block_number: sendMessage.block_number,
        values:
          type == "test" ? [] : sendMessage[type + "s"].map((item) => item.id),
      });
      loading.value = false;
      step.value = 5;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getMessages = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/message" + search);
      loading.value = false;
      messages.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      if (!meta.current_page) errors.value = e;
      loading.value = false;
    }
  };

  const checkMessage = async () => {
    try {
      errors.value = "";
      loading.value = true;
      const type = sendMessage.type;
      const manual =
        type == "test" || sendMessage.manual
          ? sendMessage.manual_number
              .split("\n")
              .filter((value) => /^[09][1-9]\d{9}$/g.test(value))
          : [];
      manual.push(...sendMessage.excel);
      let response = await axios.post("user/message/check", {
        type,
        values:
          type == "test" ? [] : sendMessage[type + "s"].map((item) => item.id),
        manual,
        block_number: sendMessage.block_number,
      });
      loading.value = false;
      sendMessage.numbers = response.data.data;
      sendMessage.number_count = response.data.count;
      numbers.value = response.data.lines;
      sendMessage.census = numbers.value[0];
      sendMessage.cost = response.data.cost;
      if (step.value <= 3) step.value = 3;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };
  return {
    sms,
    messages,
    sendMessage,
    numbers,
    getMessages,
    storeMessage,
    checkMessage,
    importContact,
  };
}
