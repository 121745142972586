<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!payment.title && errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex justify-between items-center mb-6">
                <h3 class="flex items-center md:text-26 text-2xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Payment details') }}
                </h3>
                <div class="flex justify-end order-first lg:order-last">
                    <router-link :to="{ name: 'user.payment.edit', params: { id: payment.id } }"
                        class="btn btn-outline btn-info btn-sm"
                        :class="{ 'btn-disabled': !can('payment.edit.all') && !can('payment.edit.self', payment.self) }">
                        {{ __('Edit') }}
                    </router-link>
                    <label @click="setItem(payment)" for="delete-modal"
                        :class="{ 'btn-disabled': !can('payment.delete.all') && !can('payment.delete.self', payment.self) }"
                        class="btn btn-outline btn-error btn-sm mr-2">
                        {{ __('Delete') }}
                    </label>
                </div>
            </div>
            <div class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse-plus collapse">
                <input type="checkbox" checked />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Payment information') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Title') }}: <span class="text-black dark:text-white">{{
                            payment.title
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Amount') }}: <span class="text-black dark:text-white">{{
                            payment.total?.currency()
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Pay type') }}: <span class="text-black dark:text-white">{{
                            __(payment.pay_type?.replace('_', ' ')?.capitalize()) || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Status') }}: <span class="text-black dark:text-white"
                                :class="{ 'text-success': payment.status == 'paid', 'text-error': payment.status == 'canceled' }">{{
                                    __(payment.status?.replace('_', ' ')?.capitalize()) || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Payment date') }}: <span class="text-black dark:text-white">{{
                            payment.date }}</span></p>
                        <p class="text-gray-70">{{ __('Transaction ID') }}: <span class="text-black dark:text-white">{{
                            payment.transaction_id }}</span></p>
                        <p class="text-gray-70">{{ __('User') }}: <span class="text-black dark:text-white">{{
                            payment.user }}</span></p>
                        <p class="text-gray-70">{{ __('Created At') }}: <span class="text-black dark:text-white">{{
                            payment.created_at }}</span></p>
                        <p class="text-gray-70">{{ __('Update date') }}: <span class="text-black dark:text-white">{{
                            payment.updated_at }}</span></p>
                        <div class="divider h-auto col-span-full my-0"></div>
                        <p class="text-gray-70">{{ __('Description') }}: <span class="text-black dark:text-white">{{
                            payment.description || '---' }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Appendices') }}
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-xs md:table-sm xl:table-md">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{ __('File title') }}</th>
                                    <th>{{ __('File type') }}</th>
                                    <th>{{ __('Status') }}</th>
                                    <th>{{ __('Action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in payment.attachments">
                                    <th>{{ index + 1 }}</th>
                                    <th>{{ item.name }}</th>
                                    <td>{{ item.mime_type }}</td>
                                    <td>
                                        {{ __(item.url ? 'Uploaded' : 'Uploading') }}
                                    </td>
                                    <td>
                                        <a :href="item.url" target="_blank" class="btn btn-outline btn-info btn-sm">
                                            {{ __('Show') }}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="text-center" v-if="!payment.attachments.length">
                                <tr>
                                    <td colspan="5">{{ __('No file found to display') }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete payment') }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this payment?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal"
                        :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                            __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removePayment"
                        :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>
</template>
<script setup>
import useDashboard from "../../composables/dashboard";
import { computed, onMounted, reactive, ref } from 'vue';
const { getPayment, payment, route, loading, meta, errors, remove, removePayment, loadingBtn } = useDashboard();
const baseName = route.path.split("/").reverse()[1];
const setItem = (item) => Object.assign(remove, { id: item.id, single: true });
const getData = () => {
    getPayment();
}
onMounted(() => {
    getData();
});
</script>