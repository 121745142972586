<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!clues.length && errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex flex-col md:flex-row justify-between items-center md:items-center mb-6 lg:mb-8">
                <h3 class="flex items-center md:text-26 text-2xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Clues') }}
                </h3>
                <div class="flex mt-6 md:mt-0 flex-col lg:flex-row w-full md:w-auto justify-end">
                    <div class="flex mt-4 lg:mt-0 justify-end">
                        <input
                            class="input w-full h-10 xl:pl-36 placeholder-gray-320 md:w-64 xl:w-80 ml-2 bg-gray-350 bg-opacity-10 rounded-lg  transition duration-200 text-sm text-gray-350 px-3 font-medium dark:!bg-base-300 dark:!text-white  focus:bg-blue-700 focus:bg-opacity-10 border border-blue-700  focus:border-opacity-100"
                            type="text" :placeholder="__('Search...')" v-model="searchValue">
                        <button @click="search"
                            class=" bg-blue-600 disabled:opacity-70 disabled:!bg-blue-600 flex items-center h-10 md:px-5 px-3 rounded-md text-white font-semibold text-sm transition duration-200 hover:bg-blue-800 ">
                            {{ __('Search') }}
                        </button>
                    </div>
                    <div class="flex order-first justify-between lg:order-last">
                        <div class="dropdown dropdown-hover lg:mr-4">
                            <div tabindex="0" class="btn btn-info btn-outline btn-base">
                                <span>{{ state }}</span>
                                <i
                                    class="flex md:ml-1 ml-2 md:mr-2 mr-2 h-6 border-l dark:border-blue-450 border-blue-700 border-opacity-30 transition duration-200"></i>
                                <svg width="10" height="7" viewBox="0 0 10 7" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        class="stroke-current dark:text-blue-450 text-blue-700 transition duration-200"
                                        d="M8.58333 1.60425L4.79167 5.39591L1 1.60425" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </div>
                            <ul tabindex="0"
                                class="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box lg:w-40">
                                <li class="text-xs lg:text-base" v-for="item in statusTypes"><a
                                        :class="{ 'btn-active': ($route.query.status || 'all') == item }"
                                        @click="controleState($event, item)" :data-filter-id="item">{{
                                            __(item.replace('_', ' ').capitalize()) }}</a></li>
                            </ul>
                        </div>
                        <details class="dropdown lg:mr-4 dropdown-end">
                            <summary class="btn btn-outline btn-info btn-base">{{ __('Action') }}</summary>
                            <ul class="menu dropdown-content bg-base-100 rounded-box z-[2] w-36 p-2 shadow lg:!end-auto">
                                <li v-if="can('clue.create')">
                                    <label for="excel-modal">
                                        {{ __('Import leads') }}
                                    </label>
                                </li>
                                <li> <label for="message-modal">
                                        {{ __('Send SMS') }}
                                    </label></li>
                            </ul>
                        </details>
                        <div class="flex justify-end order-first lg:order-last lg:mr-4" v-if="can('clue.create')">
                            <router-link :to="{ name: 'user.clue.create' }" class="btn btn-outline btn-info btn-base">
                                <span class="mt-0.5">{{ __('Generate leads') }}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rounded-2xl dark:bg-base-300 bg-white">
                <div class=" overflow-auto">
                    <table class="table table-xs md:table-sm xl:table-md" v-if="clues.length">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th> {{ __('Full Name') }} </th>
                                <th> {{ __('Phone number') }} </th>
                                <th> {{ __('Source') }} </th>
                                <th> {{ __('Status') }} </th>
                                <th> {{ __('User') }} </th>
                                <th> {{ __('Created At') }} </th>
                                <th> {{ __('Update date') }} </th>
                                <th> {{ __('Action') }} </th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in clues">
                                <td>{{ (meta.per_page * (meta.current_page - 1)) + index + 1 }}</td>
                                <td> {{ item.fullname }} </td>
                                <td> {{ item.phone || '---' }} </td>
                                <td> {{ item.lead_source || '---' }} </td>
                                <td> {{ __(item.status?.replace('_', ' ')?.capitalize()) || '---' }} </td>
                                <td> {{ item.user }} </td>
                                <td> {{ item.created_at }}</td>
                                <td> {{ item.updated_at }}</td>
                                <td>
                                    <router-link :to="{ name: 'user.clue.show', params: { id: item.id } }"
                                        :class="{ 'btn-disabled': !can('clue.view.all') && !can('clue.view.self', item.self) }"
                                        class="btn btn-outline btn-primary btn-sm ml-2">
                                        {{ __('Show') }}
                                    </router-link>
                                    <router-link :to="{ name: 'user.clue.edit', params: { id: item.id } }"
                                        :class="{ 'btn-disabled': !can('clue.edit.all') && !can('clue.edit.self', item.self) }"
                                        class="btn btn-outline btn-info btn-sm ml-2">
                                        {{ __('Edit') }}
                                    </router-link>
                                    <label @click="setItem(item)" for="delete-modal"
                                        :class="{ 'btn-disabled': !can('clue.delete.all') && !can('clue.delete.self', item.self) }"
                                        class="btn btn-outline btn-error btn-sm">
                                        {{ __('Delete') }}
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-span-12 flex flex-col items-center mt-9" v-else>
                        <NotFound />
                    </div>
                </div>
            </div>
            <Pagination :meta="meta" @setPage="setPage" class="!mt-6" />
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete clue') }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this clue?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal"
                        :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                            __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removeClue"
                        :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>
    <Teleport to="#app">
        <input type="checkbox" id="excel-modal" class="modal-toggle" />
        <div class="modal">
            <Form @submit="importClue" class="modal-box" v-slot="{ errors }">
                <h3 class="font-bold text-lg">{{ __('Upload Excel file') }}</h3>
                <ul class="list-group mt-3">
                    <li class="list-group-item"> ✔ ستون اول حتما شامل شماره تماس باشد.</li>
                    <li class="list-group-item"> ✔ در هر ردیف یک شماره وارد شود.</li>
                    <li class="list-group-item"> ✔ شماره بصورت کامل با فرمت<b> 09xxxxxxxxx </b> وارد نمایید.</li>
                    <li class="list-group-item"> ✔ ستون دوم باید شامل نام باشد.</li>
                    <li class="list-group-item"> ✔ ستون سوم باید شامل نام خانوادگی باشد.</li>
                    <li class="list-group-item text-blue-500"><a :href="excelFile">دانلود فایل نمونه</a>
                    </li>
                </ul>
                <div class="flex items-center mt-5 w-full">
                    <Field type="file" class="file-input file-input-bordered file-input-primary pr-0 w-full"
                        :class="{ 'file-input-error': errors.excel }" name="excel" />
                </div>
                <div class="modal-action">
                    <button class="btn btn-sm btn-success" type="submit" :disabled="loadingBtn">
                        <span class="loading loading-spinner" v-if="loadingBtn"></span>
                        {{ __('Import') }}
                    </button>
                    <label class="btn btn-sm" for="excel-modal">
                        {{ __('Cancel') }}
                    </label>
                </div>
            </Form>
        </div>
    </Teleport>
    <Teleport to="#app">
        <input type="checkbox" id="message-modal" class="modal-toggle" />
        <div class="modal">
            <Form class="modal-box overflow-visible" v-slot="{ errors }" @submit="sendSms">
                <h3 class="font-bold text-lg">{{ __('Send SMS') }}</h3>
                <div class="flex flex-col mt-4">
                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                        {{ __('Clue') }}
                    </label>
                    <Field name="clue_id" v-slot="{ field }" :value="clue.clue_id" rules="required">
                        <v-select dir="rtl" :options="meta.clue" :reduce="(option) => option.id" :class="{'input-error': errors.clue_id}"
                            v-model="clue.clue_id" :placeholder="__('Select...')" v-bind="field" class="input"
                            :filterable="false" :clearable="false" @search="fetchOptions({search: $event},'clue')" :loading="meta.type == 'clue' && loadingBtn"
                            label="name">
                            <template #no-options="{ search, searching }">
                                <template v-if="searching">
                                    <div class="opacity-60">{{ __('Nothing found to display') }}.</div>
                                </template>
                                <div v-else class="opacity-60">{{ __('Start typing to search')
                                    }}.</div>
                            </template>
                        </v-select>
                    </Field>
                </div>
                <div class="flex flex-col mt-4">
                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                        {{ __('Ready message') }}
                    </label>
                    <Field name="message_id" v-slot="{ field }" :value="clue.message_id" rules="required">
                        <v-select dir="rtl" :options="meta.save_message" :reduce="(option) => option.id" :class="{'input-error': errors.message_id}"
                            v-model="clue.message_id" :placeholder="__('Select...')" v-bind="field" class="input"
                            :filterable="false" :clearable="false" @search="fetchOptions({search: $event},'save_message')" :loading="meta.type == 'save_message' && loadingBtn"
                            label="name">
                            <template #no-options="{ search, searching }">
                                <template v-if="searching">
                                    <div class="opacity-60">{{ __('Nothing found to display') }}.</div>
                                </template>
                                <div v-else class="opacity-60">{{ __('Start typing to search')
                                    }}.</div>
                            </template>
                        </v-select>
                    </Field>
                </div>
                <div class="modal-action">
                    <button class="btn btn-sm btn-primary" type="submit" :disabled="loadingBtn">
                        <span class="loading loading-spinner" v-if="loadingBtn"></span>
                        {{ __('Send') }}
                    </button>
                    <label class="btn btn-sm" for="message-modal">
                        {{ __('Cancel') }}
                    </label>
                </div>
            </Form>
        </div>
    </Teleport>
</template>
<script setup>
import { ref, onMounted, computed, onUpdated,watch } from 'vue';
import { Field, Form } from "vee-validate";
import useDashboard from '../../composables/dashboard';
const { getClues, loading, errors, meta, router, remove, route, loadingBtn, clue, clues,sendSms,fetchOptions, importClue, removeClue } = useDashboard();
const searchValue = ref(route.query.search || '');
const statusTypes = ['all', 'new', 'assigned', 'in_process', 'converted', 'recycled', 'dead'];
const state = ref('');
const setQuery = (key, value) => {
    getClues(window.setQuery(router, route, key, value));
}
const controleState = (e, filter) => {
    state.value = e.target.textContent.trim()
    setQuery('status', filter);
}
const setPage = (p) => {
    setQuery('page', p.toString());
};
const setItem = (item) => Object.assign(remove, item);
const search = () => {
    setQuery('search', searchValue.value)
}
const getData = () => getClues(route.query)
onMounted(() => {
    getData();
});
const excelFile = computed(() => window.APP_URL + '/assets/uploads/basic/clue-example.xlsx');
onUpdated(() => state.value = document.querySelector(`[data-filter-id=${route.query.status || 'all'}]`)?.textContent?.trim())
</script>