<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <section class="lg:grid lg:grid-cols-24 lg:gap-6 flex flex-col">
                <div class="xl:col-span-18 xl:col-start-4 col-span-20 col-start-3">
                    <h2 class="text-3xl font-bold mb-3">
                        {{ __($route.params.id ? 'Edit discount' : 'Add discount') }}
                    </h2>
                    <div class="bg-white dark:bg-base-300 rounded-2xl md:px-6 px-3 sm:pb-6 sm:pt-4 py-2">
                        <Form @submit="onSubmit" v-slot="{ errors }">
                            <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4">
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Parent Type') }} <span class="text-error">*</span>
                                    </label>
                                    <Field as="select" v-model="discount.parent_type" rules="required"
                                        :class="{ 'input-error': errors.parent_type }" class="select"
                                        name="parent_type">
                                        <option v-for="item in parentTypes" :value="item">{{
                                            __(item.capitalize()) }}</option>
                                    </Field>
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __(discount.parent_type.capitalize() || 'User') }} <span class="text-error">*</span>
                                    </label>
                                    <Field name="discountable_id" v-slot="{ field }" 
                                        rules="required" :value="discount.discountable_id">
                                        <v-select dir="rtl" :options="meta[discount.parent_type]" :reduce="(option) => option.id" v-model="discount.discountable_id"
                                            :placeholder="__('Select...')" v-bind="field" class="input" :filterable="false"
                                            :class="{ 'input-error': errors.discountable_id }" @search="fetchOptions" :clearable="true"
                                            :loading="loadingBtn" label="name">
                                            <template #no-options="{ search, searching }">
                                                <template v-if="searching">
                                                    <div class="opacity-60">{{ __('Nothing found to display') }}.</div>
                                                </template>
                                                <div v-else class="opacity-60">{{ __('Start typing to search for a user')
                                                    }}.</div>
                                            </template>
                                        </v-select>
                                    </Field>
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Coupon Code') }} <span class="text-error">*</span>
                                    </label>
                                    <Field name="code" :class="{ 'input-error': errors.code }" rules="required"
                                        class="input" v-model="discount.code" />
                                </div>
                                <!-- <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Discount Type') }} <span class="text-error">*</span>
                                    </label>
                                    <Field as="select" v-model="discount.discount_type"
                                        :class="{ 'input-error': errors.discount_type }" class="select" rules="required"
                                        name="discount_type">
                                        <option value="amount">{{__('Amount') }}</option>
                                        <option value="percent">{{__('Percent') }}</option>
                                    </Field>
                                </div> -->
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Discount') }} <small>({{ __('Toman') }})</small> <span class="text-error">*</span>
                                    </label>
                                    <Field type="number" name="discount" :class="{ 'input-error': errors.discount }"
                                        rules="required" class="input" v-model="discount.discount" />
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Expiration date') }}
                                    </label>
                                    <DatePicker v-model="discount.expire_date" name="expire_date" inputClass="input"
                                        type="date" />
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Status') }} <span class="text-error">*</span>
                                    </label>
                                    <Field as="select" v-model="discount.status"
                                        :class="{ 'input-error': errors.status }" class="select" rules="required"
                                        name="status">
                                        <option value="active">{{__('Active') }}</option>
                                        <option value="expired">{{__('Expired') }}</option>
                                        <option value="used">{{__('Used') }}</option>
                                    </Field>
                                </div>
                            </div>
                            <div class="divider h-auto w-full"></div>
                            <div class="text-end">
                                <button type="submit" class="btn btn-primary px-6 mr-2">
                                    {{ __('Save Changes') }}
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { onMounted, watch } from 'vue';
import useDashboard from '../../composables/dashboard';
import { Field, Form } from "vee-validate";
import DatePicker from 'vue3-persian-datetime-picker'
const { discount, editDiscount, storeDiscount, updateDiscount, getValues, loading, loadingBtn, meta, errors, route } = useDashboard();
const parentTypes = ['clue', 'person'];
let timeout = null;
const getData = () => {
    if (route.params.id) editDiscount();
}
watch(() => discount.parent_type,() => discount.discountable_id = null);
const onSubmit = () => {
    if (route.params.id) updateDiscount();
    else storeDiscount();
}
const fetchOptions = (search) => {
    loadingBtn.value = false;
    if (timeout) clearTimeout(timeout);
    if(!search) return;
    loadingBtn.value = true;
    timeout = setTimeout(() => getValues(discount.parent_type,{search}), 500);
}
onMounted(() => {
    getData();
});
</script>