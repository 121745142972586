<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <section class="lg:grid lg:grid-cols-24 lg:gap-6 flex flex-col">
                <div class="xl:col-span-18 xl:col-start-4 col-span-20 col-start-3">
                    <h2 class="text-3xl font-bold mb-3">
                        {{ __('Add ticket')
                        }}
                    </h2>
                    <div class="bg-white dark:bg-base-300 rounded-2xl md:px-6 px-3 sm:pb-6 sm:pt-4 py-2">
                        <Form @submit="onSubmit" v-slot="{ errors }">
                            <div class="grid grid-cols-1 md:gap-x-6 gap-y-4">
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Department') }} <span class="text-error">*</span>
                                    </label>
                                    <Field as="select" name="department_id"
                                        :class="{ 'select-error': errors.department_id }" class="select"
                                        v-model="ticket.department_id">
                                        <option value="">{{ __('Select...') }}</option>
                                        <option v-for="item in meta.departments" :value="item.id">{{ item.name }}
                                        </option>
                                    </Field>
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Subject') }} <span class="text-error">*</span>
                                    </label>
                                    <Field as="input" name="subject" :class="{ 'input-error': errors.title }"
                                        rules="required" class="input" v-model="ticket.subject" />
                                </div>

                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Priority') }} <span class="text-error">*</span>
                                    </label>
                                    <Field as="select" name="priority" :class="{ 'select-error': errors.priority }"
                                        class="select" v-model="ticket.priority">
                                        <option value="">{{ __('Select...') }}</option>
                                        <option value="low">{{ __('Low') }}</option>
                                        <option value="medium">{{ __('Medium') }}</option>
                                        <option value="high">{{ __('High') }}</option>
                                    </Field>
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Description') }} <span class="text-error">*</span>
                                    </label>
                                    <Field as="textarea" name="message" :class="{ 'textarea-error': errors.message }"
                                        class="textarea min-h-32" v-model="ticket.message" />
                                </div>
                                <div class="flex flex-col col-span-full">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('File') }}
                                    </label>
                                    <Field class="file-input pr-0" @change="addAttachment($event, 'attachment')"
                                        type="file" name="file" />
                                </div>
                                <div class="overflow-x-auto col-span-full" v-if="ticket.attachments?.length">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{{ __('File title') }}</th>
                                                <th>{{ __('File type') }}</th>
                                                <th>{{ __('Status') }}</th>
                                                <th>{{ __('Action') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in ticket.attachments">
                                                <th>{{ index + 1 }}</th>
                                                <th>{{ item.name }}</th>
                                                <td>{{ item.mime_type }}</td>
                                                <td>
                                                    <div v-if="isNumeric(item.status)">
                                                        <progress class="progress progress-info w-16"
                                                            :value="item.status" max="100"></progress> <span
                                                            class="mr-0.5 inline-block w-4">{{ item.status + '%'
                                                            }}</span>
                                                    </div>
                                                    <div v-else>{{ __(item.url ? 'Uploaded' : 'Uploading') }}</div>
                                                </td>
                                                <td>
                                                    <button @click="removeFile(ticket.attachments, index)" type="button"
                                                        class="btn btn-outline btn-error btn-sm">
                                                        {{ __('Delete') }}
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="divider h-auto w-full"></div>
                            <div class="text-end">
                                <button type="submit" class="btn btn-primary px-6 mr-2">{{
                                    __('Add ticket')
                                    }}</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import useDashboard from '../../composables/dashboard';
import { Field, Form } from "vee-validate";
const { ticket, storeTicket, addTicket, uploadFile, removeFile, loading, meta, errors, route } = useDashboard();
const addAttachment = (event, type) => {
    ticket.attachments = [...ticket.attachments, { title: event.target.files[0].name, type: 'attachment', status: 'uploading' }];
    uploadFile(event, type, ticket.attachments[ticket.attachments.length - 1]);
}
const isNumeric = (value) => window.isNumeric(value);
const getData = () => {
    addTicket()
}
const onSubmit = () => {
    storeTicket()
}

onMounted(() => {
    getData();
});
</script>