import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useRole(navigation) {
  const { route, router } = navigation;
  const roles = ref([]);
  const role = reactive({ permissions: [] });
  const getRoles = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/role" + search);
      loading.value = false;
      roles.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getRole = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/role/" + route.params.id);
      loading.value = false;
      Object.assign(role, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addRole = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/role/create");
      loading.value = false;
      meta.permissions = response.data.permissions;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editRole = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/role/" + route.params.id + "/edit");
      loading.value = false;
      Object.assign(role, response.data.data);
      meta.permissions = response.data.permissions;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeRole = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/role", role);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({ name: "user.roles" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateRole = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/role/" + route.params.id, role);
      progressDialog.value = false;
      router.push({ name: "user.roles" });
      toast.success(response.data.message);
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeRole = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/role/" + role.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      getRoles(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  return {
    roles,
    role,
    getRoles,
    getRole,
    addRole,
    editRole,
    storeRole,
    updateRole,
    removeRole,
  };
}
