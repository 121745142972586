
import axios from "axios";
import { reactive,ref } from "vue";
import { loading, errors, toast ,meta, loadingBtn, remove } from "./shared";

export default function useIndustry(navigation) {
  const { route, router } = navigation;
  const industries = ref([]);
const industry = reactive({});
  const getIndustries = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/industry" + search);
      loading.value = false;
      industries.value = response.data.data;
      Object.assign(meta, response.data.meta);
      meta.industries = response.data.industries;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getSubIndustries = async (name) => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/industry/" + name + "/children");
      loading.value = false;
      industries.value = response.data.data;
      if(clue.subindustry && industries.value.indexOf(item => item.title == clue.subindustry) == -1) industries.value.push({title: clue.subindustry});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getIndustry = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/industry/" + route.params.id);
      loading.value = false;
      Object.assign(industry,response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addIndustry = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/industry/create");
      loading.value = false;
      meta.industries = response.data.industries;
      if(route.query.p_id) industry.parent_id = route.query.p_id
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editIndustry = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/industry/"+route.params.id+"/edit");
      loading.value = false;
      Object.assign(industry,response.data.data);
      meta.industries = response.data.industries;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeIndustry = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/industry",industry);
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.industries'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateIndustry = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/industry/" + industry.id, industry);
      const index = industries.value.findIndex((item) => item.id === industry.id);
      industries.value[index] = response.data.data;
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.industries'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeIndustry = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/industry/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if(remove.single){
        if(history.state.back) router.back();
        else router.push({name: 'user.industries'});
      }else getIndustries(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };
  return {
    industries,
    industry,
    getIndustries,
    getSubIndustries,
    getIndustry,
    addIndustry,
    editIndustry,
    storeIndustry,
    updateIndustry,
    removeIndustry,
  };
}
