<template>
  <Line :options="chartOptions" :data="chartData" />
</template>
  
<script setup>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'
const props = defineProps(['options']);
ChartJS.defaults.font.family = 'iranyekanBakh,sans-serif';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)
const options = props.options;
const chartData = {
  labels: options.labels,
  datasets: [
    {
      label: options.title,
      backgroundColor: document.documentElement.dataset.theme == 'dark' ? 'white' : 'black',
      borderColor: document.documentElement.dataset.theme == 'dark' ? 'white' : 'black',
      tension: 0.2,
      data: options.data
    }
  ],
}
const chartOptions = {
  scales: {
    x: {
      ticks: {
        maxTicksLimit: 14
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false
};
</script>
  