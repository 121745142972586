import axios from "axios";
import { reactive, ref } from "vue";
import { loading, errors, toast, meta, loadingBtn, remove } from "./shared";

export default function useProduct(navigation) {
  const { route, router } = navigation;
  const products = ref([]);
  const product = reactive({
    status: "active",
    type: "commodity",
    service_cost: "standard",
    sellable: 1,
    require_return_order: 0,
    attachments: [],
  });
  const getProducts = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = isObjectNotEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/product" + saerch);
      loading.value = false;
      products.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getProduct = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/product/" + route.params.id);
      loading.value = false;
      Object.assign(product, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addProduct = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/product/create");
      loading.value = false;
      Object.assign(meta, { ...response.data });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editProduct = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get(
        "user/product/" + route.params.id + "/edit"
      );
      loading.value = false;
      Object.assign(product, response.data.data);
      Object.assign(meta, { ...response.data });
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeProduct = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/product", product);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({ name: "user.products" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateProduct = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put(
        "user/product/" + route.params.id,
        product
      );
      progressDialog.value = false;
      toast.success(response.data.message);
      if (history.state.back) router.back();
      else router.push({ name: "user.products" });
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeProduct = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/product/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if (remove.single) {
        if (history.state.back) router.back();
        else router.push({ name: "user.products" });
      } else getProducts(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  return {
    products,
    product,
    getProducts,
    getProduct,
    addProduct,
    editProduct,
    storeProduct,
    updateProduct,
    removeProduct,
  };
}
