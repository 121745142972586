// dashboard.js
import {loading, errors, loadingBtn, remove, meta, step, controller} from "./shared";
import { useRoute,useRouter } from "vue-router";
import useUser from "./user";
import useRole from "./role";
import useAccount from "./account";
import useAppointment from "./appointment";
import usePerson from "./person";
import useCampaign from "./campaign";
import useOpportunity from "./opportunity";
import useTicket from "./ticket";
import useUpload from "./upload";
import useMessage from "./message";
import useSaveMessage from "./save-message";
import useIndustry from "./industry";
import useLeadSource from "./lead-source";
import useProductCategory from "./product-category";
import useProduct from "./product";
import useInvoice from "./invoice";
import usePayment from "./payment";
import useClue from "./clue";
import useDiscount from "./discount";
import useOther from "./other";

export default function useDashboard() {
  const route = useRoute();
  const router = useRouter();
  const navigation = { route, router };
  const user = useUser(navigation);
  const role = useRole(navigation);
  const clue = useClue(navigation);
  const account = useAccount(navigation);
  const appointment = useAppointment(navigation);
  const person = usePerson(navigation);
  const campaign = useCampaign(navigation);
  const opportunity = useOpportunity(navigation);
  const ticket = useTicket(navigation);
  const upload = useUpload(navigation);
  const message = useMessage(navigation);
  const saveMessage = useSaveMessage(navigation);
  const industry = useIndustry(navigation);
  const leadSource = useLeadSource(navigation);
  const productCategory = useProductCategory(navigation);
  const product = useProduct(navigation);
  const discount = useDiscount(navigation);
  const invoice = useInvoice(navigation);
  const payment = usePayment(navigation);
  const other = useOther(navigation);

  return {
    ...user,
    ...role,
    ...clue,
    ...account,
    ...appointment,
    ...person,
    ...campaign,
    ...opportunity,
    ...ticket,
    ...upload,
    ...message,
    ...saveMessage,
    ...industry,
    ...leadSource,
    ...productCategory,
    ...product,
    ...discount,
    ...invoice,
    ...payment,
    ...other,
    loading,
    router,
    route,
    meta,
    loadingBtn,
    remove,
    errors,
    step,
    controller,
  };
}
