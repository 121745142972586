<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!statistic.invoice_paid && errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-10 pb-14 " v-else>
        <div class="container">
            <div>
                <div class="grid grid-cols-24 items-center mb-8 sm:gap-4 xl:gap-y-0 gap-y-5 ">
                    <div
                        class="dark:bg-base-300 bg-white xl:col-span-8 md:col-span-8 col-span-24 rounded-lg xl:px-6 sm:px-4 px-2 py-7 flex sm:flex-row sm:space-y-0 space-y-5 flex-col items-center ">
                        <div class="w-10 h-10 rounded-lg flex items-center justify-center sm:ml-4 bg-green-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-5 h-5 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                            </svg>

                        </div>
                        <div class="sm:space-y-0 space-y-4 sm:text-right text-center">
                            <h5 class="dark:text-white text-gray-400 mb-1.5 text-xs font-medium">
                                {{ __('Paid invoices') }}
                            </h5>
                            <div class="dark:text-white text-gray-800 sm:text-21 text-lg mr-1 font-bold">
                                {{ statistic.invoice_paid?.toLocaleString() + ' ' + __('Number') }}
                            </div>
                        </div>
                    </div>

                    <div
                        class="dark:bg-base-300 bg-white xl:col-span-8 md:col-span-8 col-span-24 rounded-lg xl:px-6 sm:px-4 px-2 py-7 flex sm:flex-row sm:space-y-0 space-y-5 flex-col items-center ">
                        <div class="w-10 h-10 rounded-lg flex items-center justify-center sm:ml-4 bg-customOrange-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-5 h-5 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                            </svg>

                        </div>
                        <div class="sm:space-y-0 space-y-3 sm:text-right text-center">
                            <h5 class="dark:text-white text-gray-400 mb-1.5 text-xs font-medium">
                                {{ __('Unpaid invoices') }}
                            </h5>
                            <div class="dark:text-white text-gray-800 sm:text-21 text-lg mr-1 font-bold">
                                {{ statistic.invoice_unpaid?.toLocaleString() + ' ' + __('Number') }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="dark:bg-base-300 bg-white xl:col-span-8 md:col-span-8 col-span-24  rounded-lg xl:px-6 sm:px-4 px-2 py-6 flex sm:flex-row sm:space-y-0 space-y-5 flex-col items-center ">
                        <div class="w-10 h-10 rounded-lg flex items-center justify-center sm:ml-4 bg-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-5 h-5 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                            </svg>

                        </div>
                        <div class="sm:space-y-0 space-y-3 sm:text-right text-center">
                            <h5 class="dark:text-white text-gray-400 mb-1.5 text-xs font-medium">
                                {{ __('Total sales amount') }}
                            </h5>

                            <span class="dark:text-white text-gray-800 sm:text-21 text-lg mr-1 font-bold">
                                {{ statistic.sales?.currency() }}
                            </span>
                        </div>
                    </div>
                </div>
                <ul class="mb-6 md:flex hidden dark:bg-base-300 bg-white shadow-sm   items-center w-fit-content rounded-xl py-2 px-4"
                    id="comment-page">
                    <li class="ml-3 last:ml-0">
                        <button @click="controleState($event, 'week')" data-filter-id="week"
                            :class="{ 'dark:!bg-base-100 !bg-blue-700 !text-white ': filter === 'week' }"
                            class="flex items-center font-medium text-xl dark:hover:text-blue-450 hover:text-gray-800 transition duration-200 text-chambray-400 pt-1 h-12 px-5 rounded-lg">
                            {{ __('Last Week') }}
                        </button>
                    </li>
                    <li class="ml-3 last:ml-0">
                        <button @click="controleState($event, 'month')" data-filter-id="month"
                            :class="{ 'dark:!bg-base-100 !bg-blue-700 !text-white ': filter === 'month' }"
                            class="flex items-center font-medium text-xl dark:hover:text-blue-450 hover:text-gray-800 transition duration-200 text-chambray-400 pt-1 h-12 px-5 rounded-lg">
                            {{ __('Last Month') }}
                        </button>
                    </li>
                    <li class="ml-3 last:ml-0">
                        <button @click="controleState($event, 'year')" data-filter-id="year"
                            :class="{ 'dark:!bg-base-100 !bg-blue-700 !text-white ': filter === 'year' }"
                            class="flex items-center font-medium text-xl dark:hover:text-blue-450 hover:text-gray-800 transition duration-200 text-chambray-400 pt-1 h-12 px-5 rounded-lg">
                            {{ __('Last Year') }}
                        </button>
                    </li>
                </ul>
                <div class="relative md:hidden group mb-6 w-full" id="courses-page">
                    <div
                        class="dark:bg-base-300 bg-white cursor-pointer dark:text-white text-blue-700  py-3 flex items-center justify-between px-5 h-16 rounded">
                        <span class="flex font-medium text-lg items-center">
                            <span class="mr-2">{{ state }}</span>
                        </span>
                        <span class="border-r h-full flex items-center pr-5 border-blue-700 border-opacity-30 ">
                            <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.06597 0.94873L3.95486 4.05984L0.84375 0.94873" stroke="#3B82F6"
                                    stroke-width="1.23077" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </span>
                    </div>
                    <div class="absolute z-10 hidden group-hover:inline-block top-full pt-3 w-full">
                        <ul class="bg-white dark:bg-base-300  px-2 space-y-2 py-3 rounded">
                            <li>
                                <button @click="controleState($event, 'yesterday')" data-filter-id="yesterday"
                                    :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': filter === 'yesterday' }"
                                    class="flex items-center w-full font-medium text-chambray-400 pt-1 h-11 px-5 rounded-lg">
                                    <svg class="ml-2 -mt-1" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M20 8.8889C20 1.48151 18.235 0 10 0C1.765 0 0 1.56889 0 8.8889C0 13.609 0.833537 16.6667 4.58354 16.6667C6.58759 16.6667 7.18374 17.6186 7.73886 18.5051C8.22249 19.2774 8.67499 20 10.0002 20C11.3255 20 11.778 19.2774 12.2616 18.5051C12.8167 17.6186 13.4129 16.6667 15.4169 16.6667C19.1669 16.6667 20 13.7037 20 8.8889ZM6.66667 10C7.1269 10 7.5 9.6269 7.5 9.16667C7.5 8.70643 7.1269 8.33333 6.66667 8.33333C6.20643 8.33333 5.83333 8.70643 5.83333 9.16667C5.83333 9.6269 6.20643 10 6.66667 10ZM10.8333 9.16667C10.8333 9.6269 10.4602 10 10 10C9.53976 10 9.16667 9.6269 9.16667 9.16667C9.16667 8.70643 9.53976 8.33333 10 8.33333C10.4602 8.33333 10.8333 8.70643 10.8333 9.16667ZM13.3333 10C13.7936 10 14.1667 9.6269 14.1667 9.16667C14.1667 8.70643 13.7936 8.33333 13.3333 8.33333C12.8731 8.33333 12.5 8.70643 12.5 9.16667C12.5 9.6269 12.8731 10 13.3333 10Z">
                                        </path>
                                    </svg>
                                    {{ __('Yesterday') }}
                                </button>
                            </li>
                            <li>
                                <button @click="controleState($event, 'last_week')" data-filter-id="last_week"
                                    :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': filter === 'last_week' }"
                                    class="flex items-center w-full font-medium text-chambray-400 pt-1 h-11 px-5 rounded-lg">
                                    <svg class="ml-2 -mt-1" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M20 8.8889C20 1.48151 18.235 0 10 0C1.765 0 0 1.56889 0 8.8889C0 13.609 0.833537 16.6667 4.58354 16.6667C6.58759 16.6667 7.18374 17.6186 7.73886 18.5051C8.22249 19.2774 8.67499 20 10.0002 20C11.3255 20 11.778 19.2774 12.2616 18.5051C12.8167 17.6186 13.4129 16.6667 15.4169 16.6667C19.1669 16.6667 20 13.7037 20 8.8889ZM6.66667 10C7.1269 10 7.5 9.6269 7.5 9.16667C7.5 8.70643 7.1269 8.33333 6.66667 8.33333C6.20643 8.33333 5.83333 8.70643 5.83333 9.16667C5.83333 9.6269 6.20643 10 6.66667 10ZM10.8333 9.16667C10.8333 9.6269 10.4602 10 10 10C9.53976 10 9.16667 9.6269 9.16667 9.16667C9.16667 8.70643 9.53976 8.33333 10 8.33333C10.4602 8.33333 10.8333 8.70643 10.8333 9.16667ZM13.3333 10C13.7936 10 14.1667 9.6269 14.1667 9.16667C14.1667 8.70643 13.7936 8.33333 13.3333 8.33333C12.8731 8.33333 12.5 8.70643 12.5 9.16667C12.5 9.6269 12.8731 10 13.3333 10Z">
                                        </path>
                                    </svg>
                                    {{ __('Last Week') }}
                                </button>
                            </li>
                            <li>
                                <button @click="controleState($event, 'last_month')" data-filter-id="last_month"
                                    :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': filter === 'last_month' }"
                                    class="flex items-center w-full font-medium text-chambray-400 pt-1 h-11 px-5 rounded-lg">
                                    <svg class="ml-2 -mt-1" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M20 8.8889C20 1.48151 18.235 0 10 0C1.765 0 0 1.56889 0 8.8889C0 13.609 0.833537 16.6667 4.58354 16.6667C6.58759 16.6667 7.18374 17.6186 7.73886 18.5051C8.22249 19.2774 8.67499 20 10.0002 20C11.3255 20 11.778 19.2774 12.2616 18.5051C12.8167 17.6186 13.4129 16.6667 15.4169 16.6667C19.1669 16.6667 20 13.7037 20 8.8889ZM6.66667 10C7.1269 10 7.5 9.6269 7.5 9.16667C7.5 8.70643 7.1269 8.33333 6.66667 8.33333C6.20643 8.33333 5.83333 8.70643 5.83333 9.16667C5.83333 9.6269 6.20643 10 6.66667 10ZM10.8333 9.16667C10.8333 9.6269 10.4602 10 10 10C9.53976 10 9.16667 9.6269 9.16667 9.16667C9.16667 8.70643 9.53976 8.33333 10 8.33333C10.4602 8.33333 10.8333 8.70643 10.8333 9.16667ZM13.3333 10C13.7936 10 14.1667 9.6269 14.1667 9.16667C14.1667 8.70643 13.7936 8.33333 13.3333 8.33333C12.8731 8.33333 12.5 8.70643 12.5 9.16667C12.5 9.6269 12.8731 10 13.3333 10Z">
                                        </path>
                                    </svg>
                                    {{ __('Last Month') }}
                                </button>
                            </li>
                        </ul>
                    </div>

                </div>
                <div>
                    <div class="grid lg:grid-cols-2 gap-4 mb-24">
                        <div class="dark:bg-base-300 bg-white rounded-lg xl:p-6 sm:p-4 p-2">
                            <h2 class="text-dark-550 dark:text-white font-bold flex items-center text-xl"><i
                                    class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full"></i> {{
                                statistic.invoice_chart.title }}</h2>
                            <div class="divider col-span-full mt-0 mb-3"></div>
                            <div class="min-h-[24rem]">
                                <LineChart :options="statistic.invoice_chart" />
                            </div>
                        </div>
                        <div class="dark:bg-base-300 bg-white rounded-lg xl:p-6 sm:p-4 p-2 mt-6 lg:mt-0">
                            <h2 class="text-dark-550 dark:text-white font-bold flex items-center text-xl"><i
                                    class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full"></i> {{
                                statistic.sale_chart.title }}</h2>
                            <div class="divider col-span-full mt-0 mb-3"></div>
                            <div class="min-h-[24rem]">
                                <LineChart :options="statistic.sale_chart" />
                            </div>
                        </div>
                        <div class="dark:bg-base-300 bg-white rounded-lg xl:p-6 sm:p-4 p-2 mt-6">
                            <h2 class="text-dark-550 dark:text-white font-bold flex items-center text-xl"><i
                                    class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full"></i> {{
                                statistic.user_chart.title}}</h2>
                            <div class="divider col-span-full mt-0 mb-3"></div>
                            <div class="min-h-[24rem]">
                                <LineChart :options="statistic.user_chart" />
                            </div>
                        </div>
                        <div class="dark:bg-base-300 bg-white rounded-lg xl:p-6 sm:p-4 p-2 mt-6">
                            <h2 class="text-dark-550 dark:text-white font-bold flex items-center text-xl"><i
                                    class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full"></i> {{
                                statistic.product_chart.title}}</h2>
                            <div class="divider col-span-full mt-0 mb-3"></div>
                            <div class="min-h-[24rem]">
                                <LineChart :options="statistic.product_chart" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, onUpdated } from 'vue';
import LineChart from '../layout/LineChart.vue';
import useDashboard from '../../composables/dashboard';
const { getStatistics, loading, errors, statistic } = useDashboard();
const state = ref(null);
const filter = ref('week');
const controleState = (e, value) => {
    if (value === filter.value) return false;
    state.value = e.target.textContent.trim();
    filter.value = value;
    getData()
}

const getData = () => getStatistics('invoice', { filter: filter.value });
onMounted(() => {
    getData();
})
onUpdated(() => {
    state.value = document.querySelector(`[data-filter-id=${filter.value}]`)?.textContent?.trim();
});
</script>