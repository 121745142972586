<template>
    <Form @submit="onSubmit" v-slot="{ errors }" @invalid-submit="onInvalidSubmit">
        <div class="flex flex-col">
            <div>
                <div class="mb-10  last:mb-0">
                    <h2 class="text-dark-550  dark:text-white font-bold flex items-center text-xl">
                        <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                        {{ state }}
                    </h2>
                    <div class="divider col-span-full mt-0 mb-3"></div>
                    <div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 1">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Product name') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="product.title" rules="required"
                                    :class="{ 'input-error': errors.title }" class="input" type="text"
                                    name="title" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Status') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="product.status" rules="required"
                                    :class="{ 'input-error': errors.status }" class="select" name="status">
                                    <option value="active">{{__('Active') }}</option>
                                    <option value="inactive">{{__('Inactive') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Type') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="product.type" rules="required"
                                    :class="{ 'input-error': errors.type }" class="select" name="type">
                                    <option v-for="item in productTypes" :value="item">{{__(item.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Unit of measurement') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="product.main_usage_unit" :class="{ 'input-error': errors.main_usage_unit }"
                                    class="select" name="main_usage_unit" rules="required">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option :value="item" v-for="item in unitTypes">{{ __(item.replace('_',' ')?.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('sales price') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="product.sale_price" :class="{ 'input-error': errors.sale_price }" class="input"
                                    type="number" name="sale_price" rules="required" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Purchase price') }}
                                </label>
                                <Field v-model="product.regular_price" :class="{ 'input-error': errors.regular_price }" class="input"
                                    type="number" name="regular_price" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Product Id') }}
                                </label>
                                <Field v-model="product.part_number" :class="{ 'input-error': errors.part_number }" class="input"
                                    type="text" name="part_number" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Product Category') }}
                                </label>
                                <Field as="select" v-model="product.category_id" :class="{ 'input-error': errors.category_id }"
                                    class="select" name="category_id">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.categories" :value="item.id">{{
                                        item.title }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Cost calculation type') }}
                                </label>
                                <Field as="select" v-model="product.service_cost" :class="{ 'input-error': errors.service_cost }"
                                    class="select" name="service_cost" :disabled="product.type == 'commodity'">
                                    <option value="standard">{{ __('Standard') }}</option>
                                    <option value="unit_model">{{ __('Unit model') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Secondary unit of measurement') }}
                                </label>
                                <Field as="select" v-model="product.secondary_usage_unit" :class="{ 'input-error': errors.secondary_usage_unit }"
                                    class="select" name="secondary_usage_unit">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option :value="item" v-for="item in unitTypes">{{ __(item.replace('_',' ')?.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Number per unit') }}
                                </label>
                                <Field v-model="product.quantity_per_main_unit" :class="{ 'input-error': errors.quantity_per_main_unit }" class="input"
                                    type="number" name="quantity_per_main_unit" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('In stock') }}
                                </label>
                                <Field v-model="product.total_inventory" :class="{ 'input-error': errors.total_inventory }" class="input"
                                    type="number" name="total_inventory" />
                            </div>
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Description') }}
                                </label>
                                <Field as="textarea" v-model="product.description"
                                    :class="{ 'input-error': errors.description }" class="textarea" type="text"
                                    name="description" />
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 2">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Brand') }}
                                </label>
                                <Field v-model="product.brand"
                                    :class="{ 'input-error': errors.brand }" class="input" type="text"
                                    name="brand" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Sku') }}
                                </label>
                                <Field v-model="product.sku" :class="{ 'input-error': errors.sku }"
                                    class="input" type="text" name="sku" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Serial number') }}
                                </label>
                                <Field v-model="product.serial_number" :class="{ 'input-error': errors.serial_number }"
                                    class="input" type="text" name="serial_number" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Expiration date') }}
                                </label>
                                <DatePicker v-model="product.expiration_date" name="expiration_date" inputClass="input" type="date" />

                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Internet address') }}
                                </label>
                                <Field v-model="product.introduction_link" :class="{ 'input-error': errors.introduction_link }"
                                    class="input" type="text" name="introduction_link" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Type of invoice') }}
                                </label>
                                <Field as="select" v-model="product.invoicing_type"
                                    :class="{ 'input-error': errors.invoicing_type }" class="select" name="invoicing_type">
                                    <option value="">{{__('Select...') }}</option>
                                    <option value="priodically">{{__('Priodically') }}</option>
                                    <option value="once">{{__('Once') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Unit of measurement conversion rate') }}
                                </label>
                                <Field v-model="product.units_ratio" :class="{ 'input-error': errors.units_ratio }" class="input"
                                    type="number" name="units_ratio" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Order quantity') }}
                                </label>
                                <Field v-model="product.quantity_in_demand" :class="{ 'input-error': errors.quantity_in_demand }" class="input"
                                    type="number" name="quantity_in_demand" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Sample inventory') }}
                                </label>
                                <Field v-model="product.sample_quantity" :class="{ 'input-error': errors.sample_quantity }" class="input"
                                    type="number" name="sample_quantity" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Sellable') }}
                                </label>
                                <Field as="select" v-model="product.sellable"
                                    :class="{ 'input-error': errors.sellable }" class="select" name="sellable">
                                    <option value="1">{{__('Yes') }}</option>
                                    <option value="0">{{__('No') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Required return order') }}
                                </label>
                                <Field as="select" v-model="product.require_return_order"
                                    :class="{ 'input-error': errors.require_return_order }" class="select" name="require_return_order">
                                    <option value="1">{{__('Yes') }}</option>
                                    <option value="0">{{__('No') }}</option>
                                </Field>
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 3">
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('File') }}
                                </label>
                                <Field class="file-input pr-0" @change="addAttachment($event,'attachment')" type="file" name="file" />
                            </div>
                            <div class="overflow-x-auto col-span-full">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{{ __('File title') }}</th>
                                            <th>{{ __('File type') }}</th>
                                            <th>{{ __('Status') }}</th>
                                            <th>{{ __('Action') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in product.attachments">
                                            <th>{{ index + 1 }}</th>
                                            <th>{{ item.name }}</th>
                                            <td>{{ item.mime_type }}</td>
                                            <td>
                                                <div v-if="isNumeric(item.status)">
                                                    <progress class="progress progress-info w-16" :value="item.status" max="100"></progress> <span class="mr-0.5 inline-block w-4">{{ item.status + '%' }}</span>
                                                </div>
                                                <div v-else>{{ __(item.url ? 'Uploaded' : 'Uploading') }}</div>
                                            </td>
                                            <td>
                                    <button @click="removeFile(product.attachments,index)" type="button"
                                        class="btn btn-outline btn-error btn-sm">
                                        {{ __('Delete') }}
                                    </button>
                                </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="text-center" v-if="!product.attachments.length">
                                        <tr>
                                            <td colspan="5">{{ __('No file found to display') }}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider h-auto w-full"></div>
            <div class="text-end">
                <button type="submit" class="btn btn-primary px-6 mr-2">{{
                    __('Save Changes')
                }}</button>
            </div>
        </div>
    </Form>

</template>

<script setup>
import { onMounted, ref, warn, watch } from 'vue';
import useDashboard from '../../composables/dashboard';
import DatePicker from 'vue3-persian-datetime-picker'
import { Field, Form } from "vee-validate";
const props = defineProps(['product','meta','step','state']);
const productTypes = ['commodity','service', 'expense'];
const unitTypes = ['box','device', 'month','hour','number','branch','carton','kg','liter','meter','square_meter','sheet'];
const { uploadFile, removeFile,product,storeProduct,updateProduct,route,loading,message} = useDashboard();
Object.assign(product,props.product);
const addAttachment = (event,type) => {
    product.attachments = [...product.attachments,{title: event.target.files[0].name,type: 'attachment',status: 'uploading'}];
    uploadFile(event,type,product.attachments[product.attachments.length - 1]);
}
watch(() => product.type,value => {
    if(value == 'commodity') product.service_cost = 'standard';
})
const isNumeric = (value) => window.isNumeric(value)

const onSubmit = () => {
    if(route.params.id) updateProduct();
    else storeProduct();
}
const onInvalidSubmit = ({ errors }) => {
   
};
</script>